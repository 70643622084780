import * as classes from "./TOS.module.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { getImageByKey } from "../../scripts/getImageByKey";

const TOS = (props) => {
  const { t } = useTranslation();

  return (
    <div className={`${classes.tosContainer} font-300`}>
      <div className={`${classes.tosTitle} font-600`}>{t("tos.title")}:</div>
      Toimitusehdot ovat voimassa 1.3.2022 alkaen.
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Yleistä</div>
        <br />
        Kotikino (myöhemmin Kotikino tai Palvelu) on Nordic Content Distribution
        Oy:n ylläpitämä ja hallinnoima video ja live -lähetys sivusto
        osoitteessa:{" "}
        <NavLink to={`/`} exact activeClassName="no-class">
          https://www.kotikino.fi
        </NavLink>
        <br />
        <br />
        Palvelu ja siellä näkyvät video ja live lähetysten -materiaalit, kuvat,
        tekstit ja logot ovat Nordic Content Distribution Oy:n omaisuutta tai
        lisenssoimia materiaaleja, ja niitä ei saa ilman edellä mainittujen
        tahojen yksilöityä lupaa tallentaa, jakaa, levittää tai näyttää
        yksityisesti ja/ tai julkisesti.
        <br />
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Toimitusehdot ja video sekä live -lähetysten katsominen
        </div>
        <br />
        Video ja live -lähetyksiä voi seurata Kotikinosta erilaisia
        päätelaitteita käyttämällä. Lähetysten seuranta ja maksaminen tapahtuu
        sivuston kautta.
        <br />
        <br />
        Yhteystiedot:
        <br />
        <br />
        Nordic Content Distribution Oy
        <br />
        Vanha talvitie 11 A
        <br />
        00520 Helsinki
        <br />
        Puhelin: 010 341 1000
        <br />
        Sähköposti: <a href="mailto:tuki@kotikino.fi"> tuki@kotikino.fi.</a>
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Rekisteröityminen ja palvelupaketti
        </div>
        <br />
        Asiakas rekisteröityy Kotikino -järjestelmään ja luo sinne
        henkilökohtaisen profiilin (sähköposti sekä salasana). Tilattavat
        tuotteet valitaan ostoprosessin yhteydessä. Tehdessäsi tilauksen
        hyväksyt nämä toimitusehdot ja tuotteiden hinnat. Mikäli tilaushetkellä
        annetaan sähköpostiosoite, tilauksesta lähetetään tilausvahvistus
        sähköpostitse. Tilausvahvistuksesta ilmenevät tilatut tuotteet sekä
        hinta.
        <br />
        <br />
        Rekisteröitymällä Palveluun sitoudut: (i) ilmoittamaan todelliset,
        virheettömät, ajantasaiset ja täydelliset tiedot itsestäsi siten kuin
        Palvelun rekisteröintikaavake sinulta vaatii (“Rekisteröitymistiedot”);
        sekä (ii) ylläpitämään ja viivytyksettä päivittämään
        Rekisteröitymistiedot niiden oikeellisuuden, virheettömyyden,
        ajantasaisuuden ja täydellisyyden varmistamiseksi. Mikäli ilmoitat
        paikkansa pitämättömiä, epätarkkoja, ei-ajantasaisia tai epätäydellisiä
        tietoja, tai jos Nordic Content Distribution Oyllä on perusteltu syy
        olettaa sinun näin tehneen, Nordic Content Distribution Oy voi irtisanoa
        käyttäjätilisi tai poistaa sen väliaikaisesti käytöstä.
        <br />
        <br />
        Olet yksin vastuussa kaikesta käyttäjätiliisi liittyvästä toiminnasta.
        Sinun ei tule jakaa käyttäjätunnustasi tai salasanaasi kenenkään kanssa.
        Sitoudut ilmoittamaan Nordic Content Distribution Oylle välittömästi
        kaikesta käyttäjätiliäsi tai salasanaasi koskevasta oikeudettomasta
        käytöstä sekä mistä tahansa muusta vastaavasta tietoturvarikkomuksesta .
        <br />
        <br />
        Kun rekisteröidyt Palveluun, voit valita yhden tai useamman
        Palvelupaketin, jonka sisältö määräytyy kyseisen Palvelupaketin
        kuvauksen mukaan. Valitsemalla yhden tai useamman
        <br />
        <br />
        Palvelupaketin, sitoudut maksamaan Maksuina niiden yhteishinnan siihen
        saakka, kunnes muutat valintaasi. Nordic Content Distribution Oyllä on
        oikeus, ei kuitenkaan useammin kuin kerran 12 kuukaudessa, korottaa
        Maksuja kuluttajahintaindeksin mukaisesti.
        <br />
        <br />
        Palvelu sisältää digitaalisten sisältöjen toimituksen ja se aloitetaan
        välittömästi ostettuasi valitsemasi Palvelupaketin/Palvelupaketit.
        Hyväksyt, ettei sinulla ole tällöin peruutusoikeutta, ja ettei
        suorittamiasi maksuja palauteta, ellei toisin ole nimenomaisesti
        erikseen todettu. Voit kuitenkin aina irtisanoa tilauksesi noudattaen
        alla kohdassa (Tilauksen peruutus ja päättäminen) todettua.
        <br />
        <br />
        Jos asiakkaalle ei syystä tai toisesta tule tilausvahvistusta, tilaus ei
        todennäköisesti ole onnistunut, tai annetussa sähköpostiosoitteessa on
        ollut virhe. Kehotamme tällaisessa tilanteessa ottamaan viivytyksettä
        yhteyttä asiakaspalveluumme.
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Sopimuksen syntyminen
        </div>
        <br />
        Sopimus syntyy, kun asiakas on kirjautunut järjestelmään ja maksanut
        valitsemansa paketin. Kauppa syntyy verkkokaupan asiakkaan ja Nordic
        Content Distribution Oy:n välille.
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Maksaminen</div>
        <br />
        Verkkokaupan maksuvälittäjänä toimii Visma Pay (Visma Payments Oy,
        y-tunnus 2486559-4), joka on rekisteröity Finanssivalvonnan ylläpitämään
        maksulaitosrekisteriin. Maksamiseen siirrytään Visma Payn verkkopalvelun
        kautta ja tiliotteella ja laskulla maksun saajana näkyy Visma Pay tai
        Visma Payments Oy. Visma Pay välittää maksut verkkokauppiaalle.
        Maksaminen on turvallista, sillä kaikki maksutapahtumaa koskevat tiedot
        välitetään salattua yhteyttä käyttäen niin ettei kukaan ulkopuolinen
        taho näe maksutapahtuman tietoja.
        <br />
        <br />
        Kauppa syntyy verkkokaupan asiakkaan ja verkkokaupan välille.
        Verkkokaupan vastuulla ovat kaikki kauppaan liittyvät velvoitteet.
        <br />
        <br />
        Lue lisää Visma Paysta:{" "}
        <a href={`https://www.visma.fi/vismapay/`}>
          https://www.visma.fi/vismapay/
        </a>
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Maksutavat</div>
        <br />
        Visma Pay -palvelun kautta voit maksaa verkkopankkitunnuksilla,
        lompakolla, maksukorteilla (credit/debit), laskulla tai osamaksulla.
        Käytettävissä ovat seuraavat maksutavat: Osuuspankki, Nordea, Danske
        Bank, Oma Säästöpankki, Säästöpankki, Aktia, Paikallisosuuspankit,
        S-Pankki, Handelsbanken, Ålandsbanken, MobilePay, Masterpass, Pivo,
        Visa-, Visa Debit-, Visa Electron-, MasterCard- ja Debit MasterCard
        -kortit, sekä Jousto, Fellow Lasku ja Fellow Yrityslasku.
        <br />
        <br />
        MobilePay: Voit maksaa MobilePay-lompakollasi mikäli olet sallinut
        verkkokaupoissa maksamisen sovelluksen asetuksista. MobilePay-lompakolla
        suoritetut maksut veloitetaan lompakkoon liitetyltä maksukortilta.
        Mikäli maksun veloittaminen maksukortilta epäonnistuu,
        MobilePay-lompakolla maksaminen ei ole mahdollista verkkokaupassa.
        <br />
        <br />
        Pivo: Käyttöehdot ovat tarjolla Pivon sivuilla:{" "}
        <a href={`https://pivo.fi/kayttoehdot/pivon-kayttoehdot/`}>
          https://pivo.fi/kayttoehdot/pivon-kayttoehdot/
        </a>
        <br />
        <br />
        Jousto lasku ja osamaksu on kotimainen palvelu, jolla teet ostoksesi
        nopeasti ja turvallisesti. Jousto on tarkoitettu yksityishenkilöille,
        jotka ovat hoitaneet raha-asiansa moitteettomasti. Joustolla saat 30
        vuorokautta korotonta ja kulutonta maksuaikaa. Laskun saatuasi voit
        päättää maksatko sen kokonaan vai osissa. Osamaksulla voit maksaa
        ostoksesi jopa 36:ssa erässä, alkaen 9,90 eur/kk. Jousto osamaksun
        kustannukset ovat 3,90 eur/kk ja 19,90%:n luottokorko. Voit maksaa
        Joustolla 30–3000 euron ostoksia. Luotonmyöntäjänä toimii Aurajoki
        Nordic Oy. Lue lisää Joustosta osoitteessa{" "}
        <a href={`https://www.jousto.com`}>www.jousto.com</a>
        <br />
        <br />
        <img
          className={classes.tosImage}
          src={getImageByKey("vismaPay")}
          alt="VismaPay"
        ></img>
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Visma Pay -maksupalvelun yhteystiedot
        </div>
        <br />
        Visma Payments Oy (Y-tunnus 2486559-4)
        <br />
        Sähköposti:{" "}
        <a href="mailto:helpdesk@vismapay.com"> helpdesk@vismapay.com</a>
        <br />
        Puhelin: 09 315 42 037 (arkisin klo 8-16)
        <br />
        Postiosoite: Brahenkatu 4, 53100 Lappeenranta
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Arvosetelit, kampanjakoodit sekä voucherit
        </div>
        <br />
        Arvoseteliä voi käyttää palvelussa maksuvälineenä. Arvoseteliä tai
        voucheria voi käyttää kaikille normaaleille tuotteille ja tilauksille.
        Arvoseteliä ei voi käyttää mahdollisten lisämaksullisten palvelujen
        ostamiseen.
        <br />
        <br />
        Kampanjakoodit oikeuttavat kampanjan mukaiseen alennukseen tietyistä
        tuotteista. Kampanjakoodilla ei saa alennusta ryhmätilauksista tai
        lisämaksullisista palveluista. Kampanjakoodia ei voi käyttää, jos
        asiakkaalla on jo erikseen sovitut alennusehdot.
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Hinnat</div>
        <br />
        Tuotteiden hinnat sisältävät arvonlisäveron.
        <br />
        <br />
        Tuote myydään tilaamishetkellä verkkokaupan sivulla ilmoitettavalla
        hinnalla. Nordic Content Distribution Oy Oy pidättää oikeuden olla
        myymättä tuotetta hinnalla, joka on esim. teknisestä tai inhimillisestä
        syystä päivittynyt verkkokauppaan olennaisesti väärin. Tällaisessa
        tapauksessa sovelletaan yleisiä sopimusoikeudellisia periaatteita.
        Näissä tapauksissa Nordic Content Distribution Oy on yhteydessä
        asiakkaaseen ja kaupan purkaminen on mahdollista.
        <br />
        <br />
        Verkkokaupassa olevat hinnat ovat yksittäisten tuotepakettien
        kampanjahintoja. Ryhmätilausten (esim. voucherit) hinta on
        tilauskohtaisesti sovittavissa.
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Käyttöaika</div>
        <br />
        Asiakkaan ostama Kotikinon palvelupaketti tulee asiakkaan käytettäväksi
        välittömästi, kun verkkokauppaan on tullut vahvistus maksujärjestelmältä
        maksun hyväksymisestä.
        <br />
        <br />
        Suurempien tilausmäärien (esim. voucherit) käyttö- ja voimassaoloaika
        sovitaan tarjouksen hyväksymisen yhteydessä asiakaspalvelumme kanssa.
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Laskun maksaminen suuremmissa tilauksissa
        </div>
        <br />
        Suuremmissa tilauksissa (esim. voucherit), lasku lähetetään sähköisesti/
        sähköpostitse asiakkaan tiedoilla hänen antamaansa osoitteeseen.
        Eräpäivä on 14 päivää laskun päiväyksestä.
        <br />
        <br />
        Jos laskua ei suoriteta eräpäivään mennessä, siirretään saatava
        perittäväksi. Erääntyneiden laskujen maksuajan lykkäykset ja muut
        kyselyt tehdään muistutuslaskussa annettuihin yhteystietoihin.
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Tilauksen peruutus ja päättäminen
        </div>
        <br />
        Kuluttaja-asiakkailla on oikeus peruuttaa tilauksensa kokonaan tai
        osittain ennen palvelun käytön aloittamista (voucherit). Asiakkaan on
        ilmoitettava tilauksen peruuttamisesta kirjallisesti lähettämällä
        sähköpostia osoitteeseen{" "}
        <a href="mailto:tuki@kotikino.fi"> tuki@kotikino.fi.</a> Jos
        kuluttaja-asiakas peruuttaa tilauksen 14 päivän peruuttamisaikaa
        noudattaen, on hänen tehtävä peruutus ennen palvelun käytön aloitusta.
        Liitä palautukseen mukaan nimesi, yhteystietosi sekä tilinumerosi
        mahdollista maksunpalautusta varten.
        <br />
        <br />
        Yksittäisillä tilausvideo ostoilla ei ole palautusoikeutta. Valikoimamme
        ulkopuolelta asiakkaan pyynnöstä tehdyillä palveluilla ei ole
        palautusoikeutta.
        <br />
        <br />
        Jatkuvaveloitteisen kestotilauksen asiakas voi päättää kirjautumalla
        Kotikino -palveluun, menemällä omaan asiakasprofiiliin ja valitsemalla
        tilauksen peruutuksen.
        <br />
        <br />
        Yritysasiakkaiden tuotepalautuksissa ja peruutuksissa noudatamme
        kauppalainsäädäntöä ja hyvää kauppatapaa. Palautuksista ja peruutuksista
        tulee sopia tapauskohtaisesti Nordic Content Distribution Oy:n kanssa.
        Nordic Content Distribution Oy ei ota vastaan peruutusajan jälkeen
        tehtyjä palautuksia tai peruutuksia, joista ei ole tehty
        peruutusilmoitusta.
        <br />
        <br />
        Tilauksen peruutukset ja reklamaatiot ilmoitetaan osoitteeseen{" "}
        <a href="mailto:tuki@kotikino.fi"> tuki@kotikino.fi.</a>
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Asiakkaan maksamien rahojen palauttaminen
        </div>
        <br />
        Nordic Content Distribution Oy palauttaa kuluttaja-asiakkaalle rahat,
        jos hän peruuttaa tilauksensa edellä mainitun mukaisesti osin tai
        kokonaan ennen sen aloittamista. Maksunpalautukset suoritetaan
        maksujärjestelmäpalvelun tarjoajan toimesta. Sähköpostitse ilmoitetuille
        peruutuksille tulee varata 72 tunnin käsittelyaika arkipäivinä
        ruuhkavarauksella.
        <br />
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Reklamaatiot</div>
        <br />
        Verkkokaupalla on lakisääteinen virhevastuu myydyistä tuotteista.
        Palvelun toimimattomuudesta tai muista palvelussa ilmenneistä virheistä
        tulee reklamoida viipymättä asiakaspalveluumme. Pyrimme ensisijaisesti
        aina korjaamaan virheen ja auttamaan asiakasta palvelun käytön suhteen.
        <br />
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Rajoitukset</div>
        <br />
        Emme myy tuotteita henkilöille, joilla on aikaisemmin erääntyneitä
        laskuja Nordic Content Distribution Oy:lle tai jotka toistuvasti
        peruuttavat tilauksensa tai muutoin käyttävät väärin tarjoamiamme
        palveluita.
        <br />
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Oikeuksien myöntäminen
        </div>
        <br />
        Näiden toimitusehtojen noudattamista sekä valitsemasi
        palvelupaketin/valitsemiesi palvelupakettien mukaisten maksujen
        suorittamista vastaan Nordic Content Distribution Oy luovuttaa sinulle
        rajoitetun, ei-yksinoikeudellisen, ei-siirrettävän, ei-alilisensoitavan,
        peruutettavan lisenssin (i) avata ja katsella sivuja Palvelussa
        henkilökohtaiseen, ei-kaupalliseen käyttöösi; ja (ii) avata ja katsella
        valitsemasi Palvelupaketin/Palvelupakettien mukaista sisältöä
        henkilökohtaiseen, ei-kaupalliseen käyttöösi, ja näiden Käyttöehtojen
        kohtaa 7 noudattaen.
        <br />
        <br />
        Näiden toimitusehtojen noudattamista vastaan, siinä laajuudessa kuin
        Palvelu antaa pääsyn mihin tahansa ohjelmistoon, applikaatioon tai
        muuhun vastaavaan komponenttiin, antaa Nordic Content Distribution Oy
        sinulle rajoitetun, ei-yksinoikeudellisen, ei-siirrettävän,
        ei-alilisensoitavan ja peruutettavan lisenssin päästä ja käyttää näitä
        komponentteja niiden suoritettavassa, konekielisessä muodossa yhdessä
        Nordic Content Distribution Oy:n toimittaman Palvelun onlineversion
        kanssa. Oikeutesi käyttää edellä mainittuja komponentteja on rajoitettu
        henkilökohtaiseen, ei-kaupalliseen käyttöösi.
        <br />
        <br />
        Nordic Content Distribution Oy tarjoaa mobiiliapplikaatioita Palvelun
        joidenkin komponenttien käyttämiseksi. Näiden mobiiliapplikaatioiden
        käyttöä koskevat erilliset loppukäyttäjän lisenssiehdot.
        <br />
        <br />
        Palveluun pääsysi ja käyttösi on lisäksi noudatettava kaikilta osin myös
        muita ohjeita, jotka Nordic Content Distribution Oy on tuonut tietoosi.
        <br />
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Saatavuus, muutokset palveluun
        </div>
        <br />
        Palvelu on saatavilla sellaisten tietoverkkojen ja laitteiden
        välityksellä, joita Nordic Content Distribution Oy ei kontrolloi ja
        jotka voivat olla kolmansien osapuolten, kuten
        Internet-palveluntarjoajasi, mobiiliverkko-operaattorisi tai muiden
        vastuulla. Sen vuoksi tiedostat, että Nordic Content Distribution Oy
        suorittaa kohtuulliset toimet Palvelun tarjoamiseksi sinulle, ja että
        Nordic Content Distribution Oy ei ole vastuussa Palvelun saatavuudessa
        esiintyvistä häiriöistä .
        <br />
        <br />
        Nordic Content Distribution Oy voi milloin tahansa ennakkoilmoitusta
        antamatta muunnella, vaihtaa, vetää takaisin tai muutoin muuttaa minkä
        tahansa Nordic Content Distribution Oyn tarjoaman Palvelupaketin
        sisältöä, esimerkiksi noudattaakseen soveltuvia lakeja ja/tai
        lisenssinantajiemme vaatimuksia.
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Sisällön saatavuus</div>
        <br />
        Tiedostat ja hyväksyt, että sinulla on vain oikeus avata ja katsella
        elokuvia, televisio-sarjoja ja/tai muuta materiaalia, joihin sinulla on
        pääsy Palvelussa (”Sisältö”) niin sinä aikana kuin olet
        rekisteröityneenä Palveluun ja olet ostanut yhden tai useamman
        Palvelupaketin.
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Oikeudet sisältöön</div>
        <br />
        Hyväksyt että Nordic Content Distribution Oy ja sen lisensoijat
        omistavat kaikki oikeudet Palveluun ja Sisältöön sekä muuhun
        materiaaliin Palvelussa.
        <br />
        <br />
        Ellei näissä Käyttöehdoissa nimenomaisesti toisin sanota, sinulla ei ole
        oikeutta: (i) käyttää, levittää, siirtää, toisintaa, muokata, luoda
        muunnelmia tai julkisesti esittää Sisältöä; (ii) upottaa tai käyttää
        mitään upotustekniikkaa Sisältöön; (iii) purkaa, takaisinmallintaa tai
        palauttaa tai yrittää palauttaa lähdekoodiin tai muutoin selvittää
        Palveluun liittyvien ohjelmistojen lähdekoodi tai toiminta, tai (iv)
        käyttää Palvelua tavoitteena kehittää, markkinoida, myydä tai levittää
        mitään tuotteita tai palveluita jotka kilpailevat tai merkittävästi
        sisältävät samankaltaisia ominaisuuksia kuin Nordic Content Distribution
        Oyn tarjoamat palvelut tai tuotteet. Nordic Content Distribution Oy
        pidättää oikeuden kaikkiin oikeuksiin, joita ei nimenomaisesti ole
        annettu sinulle.
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Käytössäännöt</div>
        <br />
        Palvelun käyttöön liittyen, sinulla ei ole oikeutta:
        <br />
        <br />
        <div className={classes.tosMargin}>
          A. Liittyen mihinkään Nordic Content Distribution Oyn tarjoamaan
          tukipalveluun tai muutoin, ladata, postittaa, lähettää tai muutoin
          tuoda saataville materiaalia: (i) joka on lainvastaista, haitallista,
          uhkaavaa, herjaavaa, häiritsevää, loukkaavaa, mautonta,
          epäsiveellistä, yksityisyyttä loukkaavaa tai muutoin moraalitonta;
          (ii) jonka saataville saattaminen on lainvastaista tai on muutoin
          sopimuksenvastaista (kuten salassa pidettävää tieto tai
          yrityssalaisuudet, jotka ovat tullee sinun tietoosi työsuhteessa tai
          muu tieto, jota koskee salassapitosopimus); (iii) joka loukkaa minkä
          tahansa tahon patenttia, tavaramerkkiä, liikesalaisuutta,
          tekijänoikeuksia tai muita oikeuksia; (iv) sisältää ei-toivottua tai
          kiellonvastaista mainontaa, markkinointimateriaalia, roskapostia,
          ketjukirjeitä, pyramidipelejä tai kaupallisia sähköisiä viestejä; tai
          (v) sisältää viruksia tai mitä tahansa muuta koodia, tiedostoja tai
          ohjelmia, jotka on suunniteltu häiritsemään, tuhoamaan tai
          rajoittamaan minkä tahansa muun ohjelmiston tai laitteiston toimintaa
          <br />
          <br />
          B. Toimia tavalla, joka vaikuttaa negatiivisesti muiden
          mahdollisuuteen käyttää Palvelua
          <br />
          <br />
          C. Toimia tavalla, joka tuottaa kohtuuttoman tai suhteettoman suuren
          rasituksen Palvelulle tai sen infrastruktuurille
          <br />
          <br />
          D. Häiritä Palvelua tai palvelimia tai verkkoja, jotka ovat yhteydessä
          Palveluun, taikka olla noudattamatta mitään vaatimusta, menettelyä,
          käytänteitä tai sääntöjä, jotka liittyvät Palveluun
          <br />
          <br />
          E. Käyttää hakurobotteja, robotteja, botteja tai muita samankaltaisia
          keinoja käyttääksesi Palvelua tai ladataksesi, kopioidaksesi tai
          arkistoidaksesi mitään osaa Palvelusta
          <br />
          <br />
          F. Myydä, jakaa, siirtää, vaihtaa, lainata tai hyödyntää mihinkään
          kaupalliseen tarkoitukseen mitään osaa palvelusta, mukaan lukien mutta
          ei rajoittuen, käyttäjätiliäsi ja salasanaasi tai Sisältöä
          <br />
          <br />
          G. Rikkoa mitään kansallista tai kansainvälistä lakia tai asetusta.
        </div>
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Ehdotukset</div>
        <br />
        Mikäli päätät toimittaa Nordic Content Distribution Oylle mitään
        ehdotuksia, kommentteja, ideoita, parannuksia tai muuta palautetta
        liittyen Palveluun (“Ehdotukset”), Nordic Content Distribution
        <br />
        <br />
        Oyllä on oikeus käyttää, paljastaa, toisintaa, teettää, muokata,
        lisensoida, siirtää tai muutoin hyödyntää ja levittää Ehdotuksia millä
        tahansa tavalla, ilman viittausta sinuun ja ilman korvausta sinulle.{" "}
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Yrityksen vastuu ja vastuun rajoitukset
        </div>
        <br />
        Nordic Content Distribution Oy ei vastaa yrityksen
        vaikutusmahdollisuuksien ulkopuolella ilmenevistä ongelmista (esim.
        yksittäisen kuluttajan Internet-operaattorista johtuvista
        Internetyhteyden pätkimisistä tai laitteiston yhteensopivuusongelmista).
        <br />
        <br />
        Nordic Content Distribution Oy pidättää oikeuden toimitusehtojen ja
        hintojen sekä aikataulujen muutoksiin.
        <br />
        <br />
        Nordic Content Distribution Oy pidättää oikeuden muuttaa toimitus- ja
        sopimusehtojaan ilmoituksetta.
        <br />
        <br />
        Nordic Content Distribution Oy toimii hyvän kauppiastavan mukaisesti ja
        noudattaa toiminnassaan Suomen lakia. Asiakkaan ja Nordic Content
        Distribution Oy:n väliset riitatapaukset ratkaistaan ensisijaisesti
        neuvottelemalla ja jos sopimukseen ei päästä, niin Helsingin
        käräjäoikeudessa.
        <br />
        <br />
        Kaikissa toimintaan liittyvissä kysymyksissä ja palautteissa pyydetään
        asiakkaita ottamaan yhteyttä asiakaspalveluumme.
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Force majeure</div>
        <br />
        Nordic Content Distribution Oy ei ole vastuussa palvelun
        toimimattomuudesta, viivästyksestä tai estymisestä, mikäli syy tähän on
        Nordic Content Distribution Oy:n vaikutusmahdollisuuksien ulkopuolella
        oleva seikka, kuten tavarantoimitusten katkokset, estävät sääolosuhteet,
        luonnonmullistus, sota, onnettomuus, epidemia, tuonti- tai vientikielto,
        viranomaisten päätös, liikenteen tai energian jakelun häiriintyminen tai
        estyminen, työtaistelutoimenpide tai muu vastaava Nordic Content
        Distribution Oy:n tai aiemman myyntiportaan toimintaa estävä seikka.{" "}
      </div>
      <div className={classes.item} id="guidesDesktop">
        <div className={`${classes.tosTitle} font-400`}>Guides Desktop</div>
        <br />
        .............................................
        .............................................
        .............................................
        .............................................
      </div>
      <div className={classes.item} id="guidesMobile">
        <div className={`${classes.tosTitle} font-400`}>Guides Mobile</div>
        <br />
        .............................................
        .............................................
        .............................................
        .............................................
      </div>
      <div className={classes.item} id="guidesChromecast">
        <div className={`${classes.tosTitle} font-400`}>Guides Chromecast</div>
        <br />
        .............................................
        .............................................
        .............................................
        .............................................
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>
          Asiakasrekisteri ja rekisteriseloste
        </div>
        <br />
        Palvelun käyttö vaatii asiakkaalta kirjautumisen Kotikono
        -järjestelmään. Taustajärjestelmään tallennetaan tuolloin asiakkaan
        sähköposti sekä omavalintainen salasana.
        <br />
        <br />
        Asiakastiedot ovat luottamuksellisia eikä niitä luovuteta Nordic Content
        Distribution Oy:n ulkopuolisille osapuolille, pois lukien mahdolliset
        maksujärjestelmäpalvelut tai niihin liittyvät kysymykset.
        Sähköpostitiedotusta ja mainontaa voidaan tehdä rekisteröityneille
        asiakkaille palvelussa olevista tai sinne tulevista tuotteista.
        <br />
        <br />
        Voucher-myynnin yhteydessä, tallennamme asiakkaan yhteystietojen lisäksi
        asiakkaiden henkilöllisyystunnuksen, jota voidaan tarvittaessa käyttää
        tavanomaiseen luottokelpoisuuden varmentamiseen ja mahdollisiin
        perintätoimiin. Jos viranomainen epäilee asiakasta esim. epäillyn
        rikoksen vuoksi, voidaan näissä tapauksissa asiakas- ja henkilötietoja
        luovuttaa poliisille tai muille viranomaisille.
        <br />
        <br />
        Palvelussamme saatetaan käyttää evästeitä sivuston tilatietojen
        tallentamiseen. Tallennettavia tilatietoja ovat mm.
        sisäänkirjautumistiedot ja valitun palvelun sisältö. Evästetiedostoihin
        ei tallenneta asiakkaan henkilökohtaisia tietoja.{" "}
      </div>
      <div className={classes.item}>
        <div className={`${classes.tosTitle} font-400`}>Rekisteriseloste</div>
        <br />
        Yhteystiedot:
        <br />
        <br />
        Nordic Content Distribution Oy
        <br />
        <br />
        Vanha talvitie 11 A
        <br />
        00520 Helsinki
        <br />
        Puhelin: 010 341 1000
        <br />
        Sähköposti: <a href="mailto:tuki@kotikino.fi"> tuki@kotikino.fi</a>
        <br />
        Y-tunnus: 2990282-5{" "}
      </div>
    </div>
  );
};

export default TOS;
