import React, { useEffect, useState } from "react";
import { useMyContext } from "../../../../contexts/StateHolder";
import {
  getLatestAssets,
  getTrendingAssets,
} from "../../../../scripts/dataHandlers";
import { createToken } from "../../../../scripts/utils";
import CategoryTitle from "../../CategoryTitles/CategoryTitle";
import CategoryTitle2 from "../../CategoryTitles/CategoryTitle2/CategoryTitle2";
import useWindowDimensions from "../../../WindowDimension";
import Carousel from "react-multi-carousel";
import axios from "axios";

import RenderItem from "../../RenderItems/RenderItem";
import AssetsCustomCarousel from "../../../../Shared/CustomCarousel/AssetsCustomCarousel";
import { getUniqueSerieAndAssets } from "../../../../lib/getUniqueSerieAndAssets";

const components = {
  CategoryTitle,
  CategoryTitle2,
};

const GetLatestOrTrendingAssets = (props) => {
  const { organizationId, key, language, user } = useMyContext();
  // Destructure props.settings
  const {
    id,
    routes,
    categoryTitleComponent,
    itemImageComponent,
    itemTitleComponent,
    maintainImageAspectRatio,
    days,
    limit,
    assetProperty,
  } = props.settings;

  const placeholderItem = {
    id: "123456789087654321",
    name: "placeholderItem",
  };

  const placeHolderArray = [
    placeholderItem,
    placeholderItem,
    placeholderItem,
    placeholderItem,
    placeholderItem,
    placeholderItem,
    placeholderItem,
  ];

  const [items, setItems] = useState(null);

  const windowDimension = useWindowDimensions();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    let token;
    const fetchLatestData = async () => {
      const res = await getLatestAssets(
        organizationId,
        token,
        language,
        limit,
        assetProperty,
        user,
        source
      );
      console.log(res);

      // setItems(res?.assets);
      setItems(getUniqueSerieAndAssets(res?.assets));
    };

    const fetchPopularData = async () => {
      const res = await getTrendingAssets(
        organizationId,
        token,
        language,
        limit,
        days,
        assetProperty,
        user,
        source
      );
      console.log(res, "trending");

      // setItems(res);
      setItems(getUniqueSerieAndAssets(res));
    };

    if (organizationId && language) {
      token = createToken(organizationId * 1, "", key);

      if (token) {
        props.latestData ? fetchLatestData() : fetchPopularData();
      }
    }

    return () => source.cancel();
  }, [
    language,
    organizationId,
    key,
    props.latestData,
    days,
    limit,
    assetProperty,
    user,
  ]);

  let className1 = "";

  const renderItems = () => {
    const TitleComponent = components[categoryTitleComponent];

    return (
      <div className={`${className1} categoriesContainer`}>
        <TitleComponent
          id={"123456789098765421"}
          title={props.titleName}
          routes={routes}
          item={null}
          showViewAll={false}
          isSerie={true}
          showTitle={true}
        />
        {items?.length > 0 ? (
          <AssetsCustomCarousel>
            {items.map((item, i) => {
              let pushRoute = "";
              if (item.isSerie === true || item.series?.length > 0) {
                // pushRoute = `/${routes.serieRoute}/${organizationId}/${item.id}`;
                pushRoute = `/series/categories/${organizationId}/${item?.seriesId}`;
              } else {
                pushRoute = `/${routes.videoRoute}/${organizationId}/${item.id}`;
              }
              return (
                <RenderItem
                  key={`${id} ${i}`}
                  item={item}
                  pushRoute={pushRoute}
                  //${item.seriesId}/${item.series[0].id}`}
                  playOnClick={true}
                  itemImageComponent={itemImageComponent}
                  itemTitleComponent={itemTitleComponent}
                  imageType={"coverImage"}
                  showCategoryName={true}
                  showHoverOverlay={true}
                  hideDescription={props.hideDescription}
                  showDuration={props.showDuration}
                  showReleaseYear={props.showReleaseYear}
                  // whether to maintain aspect ratio 16/9
                  maintainImageAspectRatio={maintainImageAspectRatio}
                  isSerie={true}
                  showPublishedDate={props.showPublishedDate}
                />
              );
            })}
          </AssetsCustomCarousel>
        ) : (
          <AssetsCustomCarousel>
            {placeHolderArray.map((item, i) => {
              return (
                <RenderItem
                  key={`${id} ${i}`}
                  item={item}
                  routes={routes}
                  playOnClick={false}
                  itemImageComponent={itemImageComponent}
                  itemTitleComponent={itemTitleComponent}
                  showCategoryName={false}
                  showHoverOverlay={false}
                  hideDescription={props.hideDescription}
                  maintainImageAspectRatio={maintainImageAspectRatio}
                  isSerie={false}
                />
              );
            })}
          </AssetsCustomCarousel>
        )}
      </div>
    );
  };
  return items?.length > 0 ? renderItems() : null;
};

export default GetLatestOrTrendingAssets;
