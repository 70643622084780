import { useHistory } from "react-router-dom";
import { getImageByKey } from "../../../scripts/getImageByKey";
import { useTranslation } from "react-i18next";
import * as classes from "./GuidesBar.module.css";
import { HashLink, NavHashLink } from "react-router-hash-link";

const GuidesBar = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { routes } = props;

  return (
    <div className={classes.GuidesBar}>
      <HashLink
        to={`${routes.tos}#guidesDesktop`}
        className={classes.GuidesBarItemContainer}
        // onClick={() => history.push(`/${props.routes.guidesDesktop}`)}
      >
        <img
          className={classes.GuidesBarImage}
          src={getImageByKey("guidesDesktop")}
          title={t("guidesBar.Desktop")}
          alt="Desktop"
        />
        <div className={classes.GuidesBarItemText1}>
          {t("guidesBar.Desktop")}
        </div>
        <div className={classes.GuidesBarItemText2}>
          {t("guidesBar.Read more")}
        </div>
      </HashLink>

      <HashLink
        className={classes.GuidesBarItemContainer}
        to={`${routes.tos}#guidesDesktop`}
      >
        <img
          className={classes.GuidesBarImage}
          src={getImageByKey("guidesMobile")}
          title={t("guidesBar.Mobile")}
          alt="Mobile"
          style={props?.styles?.navBar}
        />
        <div className={classes.GuidesBarItemText1}>
          {t("guidesBar.Mobile")}
        </div>
        <div className={classes.GuidesBarItemText2}>
          {t("guidesBar.Read more")}
        </div>
      </HashLink>

      <HashLink
        className={classes.GuidesBarItemContainer}
        to={`${routes.tos}#guidesDesktop`}
      >
        <img
          className={classes.GuidesBarImage}
          src={getImageByKey("guidesChromecast")}
          title={t("guidesBar.Chromecast")}
          alt="Chromecast"
          style={props?.styles?.navBar}
        />
        <div className={classes.GuidesBarItemText1}>
          {t("guidesBar.Chromecast")}
        </div>
        <div className={classes.GuidesBarItemText2}>
          {t("guidesBar.Read more")}
        </div>
      </HashLink>
    </div>
  );
};

export default GuidesBar;
