import { useMyContext } from "../../contexts/StateHolder";
import { NavLink } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { removePunctuation } from "../../scripts/utils";

const ALL_CATEGORIES = [
  {
    title: "Series",
    id: 238508069,
  },
  {
    title: "Action",
    id: 118453982,
  },
  {
    title: "Adventure",
    id: 118453983,
  },
  {
    title: "Animation",
    id: "118453382",
  },
  {
    title: "Biography",
    id: "",
  },
  {
    title: "Comedy",
    id: 118453388,
  },
  {
    title: "Crime",
    id: 118454430,
  },
  {
    title: "Documentary",
    id: 118453390,
  },
  {
    title: "Drama",
    id: 118453391,
  },
  {
    title: "Family",
    id: 118453391,
  },
  {
    title: "Fantasy",
    id: 118454429,
  },
  {
    title: "Historical",
    id: 118454431,
  },
  {
    title: "Horror",
    id: 118453395,
  },
  {
    title: "Music",
    id: 118453981,
  },
  {
    title: "Musical",
    id: 118453966,
  },
  {
    title: "Mystery",
    id: "",
  },
  {
    title: "Romance",
    id: 118453967,
  },
  {
    title: "Sci-Fi",
    id: 118453971,
  },
  {
    title: "Short Film",
    id: 118453972,
  },
  {
    title: "Sport",
    id: 118453974,
  },
  {
    title: "Thriller",
    id: 118453976,
  },
  {
    title: "War",
    id: 118454417,
  },
  {
    id: 118453979,
    title: "Western",
  },
];

// Renders contentRatings of chosen item
const NavBarCategoryDropDown = (props) => {
  // Bring stateholders from context
  const {
    allCategories,
    setChosenCategory,
    chosenTab,
    setChosenTab,
    closeHamMenu,
    isResponsiveclose,
  } = useMyContext();

  const [viewDropdown, setViewDropdown] = useState(false);

  const { t } = useTranslation();

  const clickCategory = (categoryObj) => {
    setChosenCategory(categoryObj);
    closeHamMenu();
  };

  // Use ref to make sure react renders properly when clicking outside of box
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      // Set loginForm to false, if clicked on outside of element
      function handleClickOutside(event) {
        if (event.target.className === "navBarBTN categories selected") {
          // Do nothing as button's onClick effect will deal with toggle (clicked button)
        } else if (ref.current && !ref.current.contains(event.target)) {
          // Hide dropdown
          setViewDropdown(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const renderDropdownButtons = () => {
    return ALL_CATEGORIES.filter((el) => el?.id).map((category, i) => {
      {
        console.log("category", category?.id);
      }
      return (
        <NavLink
          className="dropdownOption"
          key={category + i}
          onClick={() => {
            clickCategory(category);
          }}
          to={`/${props.route}/${category.id}`}
          value={category.id}
          style={props.styles?.dropdownOption}
        >
          {removePunctuation(category.title.toUpperCase())}
        </NavLink>
      );
    });
  };

  const renderDropdown = () => {
    return (
      <div
        className="categoriesDropdown"
        style={
          viewDropdown
            ? {
                ...props.styles?.categoriesDropdown,
                display: "flex",
              }
            : {
                ...props.styles?.categoriesDropdown,
                display: "none",
              }
        }
        ref={wrapperRef}
      >
        {allCategories ? renderDropdownButtons() : null}
      </div>
    );
  };
  return (
    <div
      className={
        chosenTab === "categories"
          ? "navBarBTN categories selected "
          : "navBarBTN categories "
      }
      style={props.styles?.navBarBTN}
      onClick={() => {
        setChosenTab("categories");
        setViewDropdown(viewDropdown ? false : true);
      }}
    >
      <div className="categories_info">
        {t("navBarCategoryDropDown.categories")}
        {!isResponsiveclose ? <FiChevronDown /> : <FiChevronRight />}
      </div>

      {renderDropdown()}
    </div>
  );
};

export default NavBarCategoryDropDown;
