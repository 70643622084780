import { useMyContext } from "../../../contexts/StateHolder";
import { useEffect, useState } from "react";
import { createToken } from "../../../scripts/utils";
import * as classes from "./Promo.module.css";
import PromoItem from "./PromoItem";
import { useQuery } from "react-query";
import { reactQueryGetPromo } from "../../../scripts/dataHandlerReactquery";

const Promo = (props) => {
  const { backgroundBanner, groupItemId, routes, assetProperty } =
    props.settings;

  const [promoItems, setPromoItems] = useState([]);
  const backgroundBannerItem = {
    bannerImageSmall:
      "https://icareus-suite.secure2.footprint.net/image/image_gallery?img_id=156407757",
  };

  // Bring stateholders from context
  const { key, language, organizationId, user } = useMyContext();

  const fetchPromo = () => {
    const token = createToken(organizationId, groupItemId, key);
    return reactQueryGetPromo(
      organizationId,
      groupItemId,
      token,
      language,
      assetProperty,
      user
    );
  };

  const { data, isLoading, isSuccess } = useQuery(
    `${groupItemId}getPromo`,
    fetchPromo,
    {
      // by default the cache time is 5 minutes
      cacheTime: 300000,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setPromoItems(data?.data?.assets);
    }
  }, [isLoading, isSuccess]);

  if (backgroundBannerItem && promoItems?.length > 0) {
    return (
      <div className={classes.primarycontainer_Promo}>
        <img
          className={classes.backgroundImage}
          src={backgroundBannerItem.bannerImageSmall}
          alt="img"
          style={backgroundBanner ? { display: "flex" } : { display: "none" }}
        />
        <div
          className={classes.secondaryContainer}
          style={
            backgroundBanner ? { position: "absolute" } : { position: "unset" }
          }
        >
          {promoItems ? (
            <PromoItem assets={promoItems} routes={routes} />
          ) : null}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Promo;
