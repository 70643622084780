import React from "react";
import * as classes from "./ItemImage1.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { getImageByKey } from "../../../../../scripts/getImageByKey";
import { convertDuration } from "../../../../../scripts/utils";

export default function ItemImage1(props) {
  const pickImage = (item) => {
    // console.log("item,props.imageType", item, props.imageType);
    if (props.imageType === "thumbnail") {
      if (props.imageChannel) {
        return item.imageSmall;
      }
      return item.thumbnailSmall
        ? item.thumbnailSmall
        : item.serie
        ? item.serie.coverImageSmall
        : getImageByKey("comingSoonThumbnail");
    } else if (props.imageType === "thumbnailSerie") {
      return item.thumbnailSmall || getImageByKey("comingSoonThumbnailSerie");
    } else if (props.imageType === "coverImage") {
      return (
        item.coverImageSmall ||
        item.thumbnailSmall ||
        getImageByKey("comingSoonThumbnailSerie")
      );
    } else if (item.serie) {
      return item.serie.coverImageSmall;
    } else {
      return item.coverImageSmall ? item.coverImageSmall : item.thumbnailSmall;
    }
  };

  let imageClassName = classes.ViewedSectionSecondary__3videos__image;
  if (props.imageChannel) {
    imageClassName = classes.ViewedSectionSecondary__3videos__image_channel;
  } else if (props.maintainImageAspectRatio) {
    imageClassName = `${
      classes.ViewedSectionSecondary__3videos__image
    }  ${"image_wrapper"}`;
  } else {
    // 200*300 image not 16:9
    if (props.imageType === "coverImage") {
      imageClassName = `${classes.ViewedSectionSecondary__3videos__image}  ${
        props.imageType === "coverImage" && "image_wrapper_cover"
      }`;
    }
  }
  return (
    <div
      className={`${imageClassName} ${
        props.extraClass ? props.extraClass : null
      }`}
      onClick={props.onClick}
      style={{ position: "relative !important" }}
    >
      {/* {props.item && <img src={pickImage(props.item) ? relpaceCDNImageURL(pickImage(props.item)) : getImageByKey("comingSoon")} alt="" />} */}

      {props.item && (
        <LazyLoadImage
          effect="blur"
          className=" "
          src={
            pickImage(props.item)
              ? pickImage(props.item)
              : getImageByKey("comingSoon")
          }
          width={"100%"}
          height={"100%"}
          alt=""
        />
      )}

      {/* to define the position of play button */}
      {
        <div
          className={`${classes.ViewedSectionSecondary__3videos__image_Overlay} ${classes.ViewedSectionSecondary__3videos__image_Overlay_blur} overlay_blur`}
        >
          <div
            className={`${classes.ViewedSectionSecondary__3videos__videoicon_overlay} playicon_overlay`}
            style={{
              top: props.top ? props.top : "50%",
              left: props.left ? props.left : "50%",
            }}
          >
            {!props.hidePlayButton && (
              <FontAwesomeIcon icon="play" size={props.playIconSize} />
            )}
            {props.hidePlayButton && (
              <div
                className={`${classes.ViewedSectionSecondary__3videos__videoicon_overlay} playicon_overlay`}
                style={{
                  top: props.top ? props.top : "50%",
                  left: props.left ? props.left : "50%",
                  color: "white",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  {props.item.title || props.item.name}
                </div>
                <button
                  className={`${classes.ViewedSectionSecondary__3videos__videoicon_overlay_view_all}`}
                >
                  View All
                </button>
              </div>
            )}
          </div>
        </div>
      }
      {props.showLikeIcon && (
        <div className={classes.ViewedSectionSecondary__3videos__image_overlay}>
          <div className={classes.wrapper_bottom}>
            {props.likes && (
              <p className={classes.wrapper_bottom_likes}>
                <FontAwesomeIcon icon="thumbs-up" />
                {props.likes}k
              </p>
            )}
            <p className={classes.wrapper_bottom_duration}>
              {convertDuration(props.duration)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
