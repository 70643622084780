import { useQuery } from "react-query";

import { reactQuerygetSubCategories } from "../../../scripts/dataHandlerReactquery";
import * as settings from "../../../configs/config_settings.json";
import { createToken } from "../../../scripts/utils";
import { useMyContext } from "../../../contexts/StateHolder";
import SingleCateoryAssets from "./SingleCateoryAssets";

const AssetsMode1 = (props) => {
    //  mode 1: show listed categories in config or from request

    // We have to send API request so that we can get name of category

    const {
        id,
        groupItemId,

        assetProperty,
    } = props.settings;
    const { organizationId, key } = settings.organization;
    const { language } = settings.language;

    const { user } = useMyContext();

    const fetchSubCategories = () => {
        const token = createToken(organizationId, groupItemId, key);

        return reactQuerygetSubCategories(organizationId, token, groupItemId, language, user, assetProperty);
    };

    const { data: categories, isLoading } = useQuery(`${id}AssetMode1${groupItemId}`, fetchSubCategories, {
        // by default the cache time is 5 minutes
        cacheTime: 300000,
        staleTime: Infinity,
    });

    console.log("assets", categories?.data?.data, isLoading);

    if (!isLoading) {
        // if cat list is 1 we dont get categories?.data?.data[0].groupItems->it is empty
        if (categories?.data?.data?.length === 1) {
            return <SingleCateoryAssets category={categories?.data?.data[0]} {...props} key={categories?.data?.data[0]?.id} />;
        } else {
            return categories?.data?.data[0].groupItems.map((el, i) => <SingleCateoryAssets category={el} {...props} key={el?.id || i.toString()} />);
        }
    } else {
        return null;
    }
};

export default AssetsMode1;
