import { useMyContext } from '../../contexts/StateHolder';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangePassword from './ChangePassword/ChangePassword';
import UserDetails from './UserDetails/UserDetails';
import CurrentSubscription from './Subscriptions/CurrentSubscription';
import * as classes from './Profile.module.css';

const Profile = (props) => {
  const { fields, showSubscriptions } = props.settings;

  // Bring stateholders from context
  const { user, chosenMenuOptionProfile } = useMyContext();

  // Setup translate function
  const { t } = useTranslation();

  // Render view, depending on selected menu option
  const renderOptions = () => {
    if (chosenMenuOptionProfile === 'userDetails') {
      return <UserDetails fields={fields} showSubscriptions={showSubscriptions} />;
    } else if (chosenMenuOptionProfile === 'subscriptions') {
      return <CurrentSubscription showSubscriptions={showSubscriptions} user={{...user}} />;
    } else if (chosenMenuOptionProfile === 'changePassword') {
      return <ChangePassword showSubscriptions={showSubscriptions} />;
    }
  };

  if (user.loggedIn) {
    return (
      <div className={classes.profileContainer}>
        <div className={classes.profileOptionView}>
          <div>{renderOptions()}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.profileContainer}>
        <div className={classes.profileMessage}>
          {t('profileMenu.loginFirst')}
        </div>
      </div>
    );
  }
};

export default Profile;
