export const getAssetPushRoute = (
  el,
  routes,
  organizationId,
  historyPush,
  history
) => {
  console.log(el);
  // If item has predefined pageUrl
  if (el.pageUrl && el.pageUrl !== "") {
    // Absolute or relative path -checker
    const urlRegExp = /^https?:\/\//i;
    if (urlRegExp.test(el.pageUrl)) {
      // Is absolute path, redirect user to location
      window.location.href = el.pageUrl;
    } else {
      // Is relative path, push to relative path
      if (historyPush) {
        return history.push(el.pageUrl);
      } else {
        return el.pageUrl;
      }
    }
  } else if (el.isSerie || el?.series?.length > 0 || el?.isSerieCategory) {
    // If el is main serie, set categories route as pushRoute

    // isSerieCategory is manual

    if (historyPush) {
      return history.push(
        `/series/categories/${organizationId}/${
          el?.isSerieCategory ? el?.id : el?.seriesId
        }`
      );
    } else {
      return `/series/categories/${organizationId}/${
        el?.isSerieCategory ? el?.id : el?.seriesId
      }`;
    }
  } else if (!el.isSerie) {
    // If el is movie/video asset, set videoRoute as pushRoute
    if (historyPush) {
      return history.push(`/${routes.videoRoute}/${organizationId}/${el.id}`);
    } else {
      return `/${routes.videoRoute}/${organizationId}/${el.id}`;
    }
  }
};
