import { useMyContext } from "../../../contexts/StateHolder";

import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { createToken } from "../../../scripts/utils";
import RenderBanner2 from "./RenderBanner1/RenderBanner2";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import useWindowDimensions from "../../WindowDimension";

import * as settings from "../../../configs/config_settings.json";

import React from "react";

import { useQuery } from "react-query";
import { reactQuerygetBanner } from "../../../scripts/dataHandlerReactquery";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getAssetPushRoute } from "../../../lib/getAssetPushRoute";

const USE_HISTORY_PUSH = true;

const Banner = (props) => {
  // Destructure props.settings
  const {
    groupItemId,
    routes,
    bannerComponent,
    slickSettings,
    showDuration,
    showTitle,
    showActionButton,
    showDescription,
    showReleaseYear,
    showCategory,
    showContentRating,
    id,
  } = props.settings;

  // Bring stateholders from context
  const { setChosenItem, setChosenCategory, user } = useMyContext();

  const { organizationId, key } = settings.organization;
  const { language } = settings.language;

  const [deviceType, setDeviceType] = useState(null);

  const windowDimension = useWindowDimensions();

  const history = useHistory();

  const components = {
    RenderBanner2,
  };
  useEffect(() => {
    if (windowDimension.width <= 550) {
      setDeviceType("mobile");
    } else {
      setDeviceType(null);
    }
  }, [windowDimension.width]);

  const clickItem = (item) => {
    setChosenItem(item);

    setChosenCategory({ id: item.groupItemIds, title: item.groups });

    getAssetPushRoute(item, routes, organizationId, USE_HISTORY_PUSH, history);
  };

  const fetchBanner = () => {
    const token = createToken(organizationId, groupItemId, key);

    return reactQuerygetBanner(
      organizationId,
      groupItemId,
      token,
      language,
      user
    );
  };
  const { data: banners, isLoading } = useQuery(
    `${id}Banner${groupItemId}`,
    fetchBanner,
    {
      // by default the cache time is 5 minutes
      cacheTime: 300000,
      staleTime: Infinity,
    }
  );

  console.log("banners", banners, isLoading);

  const RenderBannerComponent = components[bannerComponent];
  const skeletonItem = [1];

  if (isLoading) {
    let updatedSlickSetting = { ...slickSettings };
    updatedSlickSetting.dots = false;
    updatedSlickSetting.showStatus = false;
    updatedSlickSetting.showIndicators = false;
    updatedSlickSetting.showThumbs = false;

    return (
      <Carousel {...updatedSlickSetting}>
        {skeletonItem.map((el2) => (
          <SkeletonTheme key={el2}>
            <p>
              <Skeleton
                key={el2}
                width={"100%"}
                height={"350px"}
                highlightColor="#272727"
                duration={2}
              />
            </p>
          </SkeletonTheme>
        ))}
      </Carousel>
    );
  }

  if (banners?.data?.assets) {
    return (
      <div className="bannerContainer">
        <Carousel
          {...slickSettings}
          centerMode={!deviceType ? true : false}
          centerSlidePercentage={!deviceType ? "100" : "100"}
          showArrows={!deviceType ? true : false}
        >
          {banners?.data?.assets
            ? banners?.data?.assets.map((el) => {
                el.isSerie = el?.series?.length > 0 ? true : false;
                return (
                  <RenderBannerComponent
                    clickItem={clickItem}
                    item={el}
                    id={el.id}
                    key={el.id}
                    deviceType={deviceType}
                    showDuration={showDuration}
                    showtitle={showTitle}
                    showActionButton={showActionButton}
                    showDescription={showDescription}
                    showCategory={showCategory}
                    showReleaseYear={showReleaseYear}
                    showContentRating={showContentRating}
                  />
                );
              })
            : null}
        </Carousel>
      </div>
    );
  }
};

export default Banner;
