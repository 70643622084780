import { useQuery } from "react-query";

import * as settings from "../../../configs/config_settings.json";
import { createToken } from "../../../scripts/utils";
import { useMyContext } from "../../../contexts/StateHolder";
import SingleCateoryAssets from "./SingleCateoryAssets";
import { reactQuerygetSubCategories } from "../../../scripts/dataHandlerReactquery";

const AssetsMode4 = (props) => {
  // mode 4: get subcategories and their assets, ignoring hidden categories from asset folders

  // Here we get the subcategories from given categoryId.They are in in first array inside groupItems. so we map over all the subcat and send GetAssets calls

  const {
    id,

    groupItemId,

    assetProperty,
  } = props.settings;
  const { organizationId, key } = settings.organization;
  const { language } = settings.language;

  const { user } = useMyContext();

  const fetchSubCategories = () => {
    const token = createToken(organizationId, groupItemId, key);

    return reactQuerygetSubCategories(
      organizationId,
      token,
      groupItemId,
      language,
      user,
      assetProperty
    );
  };

  const { data: categories, isLoading } = useQuery(
    `${id}AssetMode4${groupItemId}`,
    fetchSubCategories,
    {
      // by default the cache time is 5 minutes
      cacheTime: 300000,
      staleTime: Infinity,
    }
  );

  console.log("assets", categories?.data?.data, isLoading);

  if (!isLoading) {
    return categories?.data?.data[0].groupItems.map((el, i) => (
      <SingleCateoryAssets
        category={el}
        {...props}
        key={el?.id || i.toString()}
      />
    ));
  } else {
    return null;
  }
};

export default AssetsMode4;
