import { useMyContext } from "../../contexts/StateHolder";
import * as classes from "./DetaillsFolderNames.module.css";
import { useHistory } from "react-router-dom";

// Renders contentRatings of chosen item
const RenderFolderNames = (props) => {
    const history = useHistory();
    // Bring stateholders from context
    const { chosenItem } = useMyContext();

    const renderItem = props?.item || chosenItem;

    const clickHandler = (el) => {
        if (renderItem.isSerie) {
            return;
        }
        history.push(`/${props.routes.svodCategoriesRoute}/${el.id}`);
    };

    const renderFolderNames = () => {
        return renderItem.folders.map((cat, i) => {
            return (
                <div
                    className={`${classes.folderName} font-300`}
                    style={{
                        color: "white",
                    }}
                    key={cat.id}
                    onClick={() => clickHandler(cat)}
                >
                    {cat.name.charAt(0).toUpperCase() + cat.name.slice(1)}
                    {i < renderItem?.folders?.length - 1 ? " ," : null}
                </div>
            );
        });
    };

    return <div className="folderNamesList">{renderItem && renderFolderNames()}</div>;
};

export default RenderFolderNames;
