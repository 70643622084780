import React, { useEffect, useState } from 'react';
import * as classes from './ItemTitle2.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  convertDuration,
  trancuate,
  trancuateDesc,
} from '../../../../../scripts/utils';
import { useTranslation } from 'react-i18next';
//import DetailsFolderNames from '../../../Details/DetailsFolderNames';
import useWindowDimensions from '../../../../WindowDimension';
import moment from 'moment';

export default function ItemTitle2(props) {
  const [text, setText] = useState(null);
  const [textDescription, setTextDescription] = useState(null);

  const { t } = useTranslation();

  const windowDimension = useWindowDimensions();
  useEffect(() => {
    if (props.item) {
      let textVal =
        props.item.name || props.item.title || props.item.serie?.title;

      let textDesc = props.item?.description;

      let textDescResult;

      let result;

      if (windowDimension.width >= 550) {
        result = trancuate(textVal, 22);
        textDescResult = trancuateDesc(
          textDesc.replace(/<\/?[^>]+(>|$)/g, '').replace(/>\s+</g, '><'),
          90
        );
      } else {
        result = trancuate(textVal, 70);
        textDescResult = trancuateDesc(
          textDesc.replace(/<\/?[^>]+(>|$)/g, '').replace(/>\s+</g, '><'),
          80
        );
      }

      setText(result.toUpperCase());
      setTextDescription(textDescResult);
    }
  }, [props.item, windowDimension.width]);

  return (
    text && (
      <div
        className={classes.itemTitleContainer}
        onClick={props.onClick && props.onClick}
      >
        <div
          className={props.imageType === "coverImage" ? classes.ViewedSectionSecondary__3videos__text_description_cover : classes.ViewedSectionSecondary__3videos__text_description_thumbnail }
          style={props.style}
        >
          <div
            className={`${classes.ViewedSectionSecondary__3videos__text_description_heading}   font-300 itemTitleHeadingColor`}
            style={props.textStyle}
          >
            {text}
          </div>
          {!props.hideDescription && (
            <div
              className={`${classes.ViewedSectionSecondary__3videos__text_description_heading_desc}   font-150 thin `}
              style={props.textStyle}
              dangerouslySetInnerHTML={{ __html: textDescription }}
            ></div>
          )}

          <div
            className={`${classes.ViewedSectionSecondary__3videos__text_description_duration} font-100`}
          >
            {props.showCategoryName && props.item.folders && (
              <div>
                {/* We are taking only max 3 category name */}
                {props.item.folders
                  .slice(0, 3)
                  .map((el) => el.name.charAt(0).toUpperCase() + el.name.slice(1))
                  .join(' , ')}
              </div>
            )}

            <div className={classes.yearAndDurationRow}>

              {props.showReleaseYear && (
                <div className={classes.releaseYearRow}>
                  {props.item.releaseYear && props.item.releaseYear !== 0
                    ? props.item.releaseYear
                    : null}
                </div>
              )}

              {props.showReleaseYear &&
                props.showDuration &&
                props.item.releaseYear !== 0 && (
                  <div className={classes.divider}>{'-'}</div>
                )}

              <div className={classes.durationRow}>
                {props.showDuration && props.item.duration && props.item.duration !== 0 ?
                  convertDuration(props.item.duration) : null}
              </div>

            </div>
          </div>

          {props.showStatus && (
            <div
              className={`${classes.ViewedSectionSecondary__3videos__text_description_status} font-000`}
            >
              {t('itemTitle.available')}
            </div>
          )}
          {props.showPublishedDate && (
            <div
              className={`${classes.ViewedSectionSecondary__3videos__text_description_status} font-000`}
            >
              {moment(props.item.date).locale('fr').format('l')}
            </div>
          )}

          {props.showActions && (
            <div
              className={classes.ViewedSectionSecondary__3videos__text_actions}
            >
              <p>
                {' '}
                <FontAwesomeIcon icon='eye' /> {props.views}k{' '}
                {t('itemTitle.views')}
              </p>
              <p>
                <FontAwesomeIcon icon='comment' /> {props.comments}
              </p>
            </div>
          )}
        </div>
      </div >
    )
  );
}
