import React, { useReducer, useEffect, useState } from "react";
import { validate } from "../Validation/Validator";
import classes from "./Input.module.css";
import { useMyContext } from "../../contexts/StateHolder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import fi_FI from "date-fns/locale/fi";
import el_GR from "date-fns/locale/el";
import en_US from "date-fns/locale/en-US";
import sv_SE from "date-fns/locale/sv";
registerLocale("fi_FI", fi_FI);
registerLocale("el_GR", el_GR);
registerLocale("en_US", en_US);
registerLocale("sv_SE", sv_SE);

const inputReducer = (state, action) => {
  const { type, value, validators } = action;

  switch (type) {
    case "CHANGE":
      return {
        ...state,
        value,
        isValid: validate(value, validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

export const Input = ({
  onInput,
  id,
  validators,
  type,
  element,
  placeholder,
  rows,
  errorText,
  label,
  initialValid,
  initialValue,
  iconName,
  selectData,
  disabled,
  style,
}) => {
  const initialState = {
    value: initialValue || "",
    isValid: initialValid || false,
    isTouched: false,
  };

  const [state, dispatch] = useReducer(inputReducer, initialState);
  const { value, isValid, isTouched } = state;
  const [startDate, setStartDate] = useState(value ? new Date(value) : null);

  // Setup translate function
  const { t } = useTranslation();

  // Bring stateholders from context
  const { language } = useMyContext();

  // as soon as the Input element is loaded it takes the value from the state and sends to the onInput which is our
  useEffect(() => {
    // if the function onInput is called it will the function InputHandler in form-hook(for validity check). ALl the data will be passed from here. This function Inputs all handle one input whereas onInput which in returns InputHandler is responsible for handling all the form
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changehandler = (e) => {
    console.log("changehandler ", e.target.value);
    dispatch({
      type: "CHANGE",
      value: e.target.value,
      validators,
    });
  };

  const touchedHandler = () => {
    dispatch({ type: "TOUCH" });
  };
  let elements;

  if (element === "input") {
    elements = (
      <div
        className={`${classes.formInput} ${
          !isValid && isTouched && classes.formInputInvalid
        } `}
        style={style}
      >
        {!iconName ? (
          <p>{label}</p>
        ) : (
          <p>
            {<FontAwesomeIcon icon={iconName} className={classes.iconColor} />}
          </p>
        )}
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          onChange={changehandler}
          onBlur={touchedHandler}
          value={value}
          disabled={disabled}
        />
      </div>
    );
  } else if (element === "textarea") {
    elements = (
      <textarea
        id={id}
        onChange={changehandler}
        onBlur={touchedHandler}
        value={value}
        rows={rows || 3}
      />
    );
  } else if (element === "select") {
    elements = (
      <div
        className={`${classes.formInput} ${
          !isValid && isTouched && classes.formInputInvalid
        } `}
        style={style}
      >
        {!iconName ? (
          <p>{label}</p>
        ) : (
          <p>
            {<FontAwesomeIcon icon={iconName} className={classes.iconColor} />}
          </p>
        )}
        <select value={value} onChange={changehandler} onBlur={touchedHandler}>
          <option value={placeholder}>{placeholder}</option>
          {selectData.map((el) => (
            <option key={el} value={el}>
              {el}
            </option>
          ))}
        </select>
      </div>
    );
  } else if (element === "dateOfBirth") {
    elements = (
      <div
        className={`${classes.formInput} ${
          !isValid && isTouched && classes.formInputInvalid
        } `}
        style={style}
      >
        {!iconName ? (
          <p>{label}</p>
        ) : (
          <p>
            {<FontAwesomeIcon icon={iconName} className={classes.iconColor} />}
          </p>
        )}
        <DatePicker
          className={classes.dateOfBirth}
          locale={language}
          selected={startDate}
          placeholderText={t("signUpForm.dateOfBirth")}
          peekNextMonth
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          onBlur={touchedHandler}
          onChange={(date) => {
            setStartDate(date);
            changehandler({ target: { value: date } });
          }}
        />
      </div>
    );
  } else if (element === "textarea") {
    elements = (
      <textarea
        id={id}
        onChange={changehandler}
        onBlur={touchedHandler}
        value={value}
        rows={rows || 3}
      />
    );
  }
  return (
    <>
      {elements}
      {!isValid && isTouched && (
        <p className={`${classes.errorText} font-300`}>{errorText}</p>
      )}
    </>
  );
};
