import { useMyContext } from "../../contexts/StateHolder";
import { convertDuration } from "../../scripts/utils";
import { useTranslation } from "react-i18next";

import * as classes from "./DetailsInfoData.module.css";

// Renders contentRatings of chosen item
// Priorizes props, if used as child.
const RenderInfoData = (props) => {
    // Bring stateholders from context
    const { chosenItem } = useMyContext();

    // Setup translation function
    const { t } = useTranslation();

    const renderInfoData = (data) => {
        return (
            <div className={`font-300 details2InfoColor ${classes.detailsInfoData}`}>
                <div>
                    <div className={"dataTitle"}>{t("detailsInfo.director")}</div>

                    <div className={"dataValue thin-book font-200"} font-400>
                        {data.director ? data.director : "-"}
                    </div>
                </div>
                <div>
                    <div className={"dataTitle"}>{t("detailsInfo.actors")}</div>

                    <div className={"dataValue thin-book font-200"}>{data.actors ? data.actors.replace(/,/g, ", ") : "-"}</div>
                </div>

                <div>
                    <div className={"dataTitle"}>{t("detailsInfo.releaseYear")}</div>

                    <div className={"dataValue thin-book font-200"}>{data.releaseYear ? data.releaseYear : "-"}</div>
                </div>
                <div>
                    {data.duration ? <div className={"dataTitle"}>{t("detailsInfo.duration")}</div> : null}

                    {data.duration ? <div className={"dataValue thin-book font-200"}>{convertDuration(data.duration)}</div> : null}
                </div>

                <div>
                    <div className={"dataTitle"}>{t("detailsInfo.languages")}</div>

                    <div className={"dataValue thin-book font-200"}>
                        {props.item.spokenLanguages && props.item.spokenLanguages !== "" ? props.item.spokenLanguages.split(",").join(", ").toUpperCase() : "-"}
                    </div>
                </div>

                <div>
                    <div className={"dataTitle"}>{t("detailsInfo.subtitles")}</div>

                    <div className={"dataValue thin-book font-200"}>-</div>
                </div>
            </div>
        );
    };

    return renderInfoData(props.item ? props.item : chosenItem);
};

export default RenderInfoData;
