import { useMyContext } from "../../../contexts/StateHolder";
import { useHistory } from "react-router-dom";
import ItemTitle from "./ItemTitles/ItemTitle";
import ItemImage1 from "./ItemImages/ItemImage1/ItemImage1";
import ItemTitle1 from "./ItemTitles/ItemTitle1/ItemTitle1";
import ItemTitle2 from "./ItemTitles/ItemTitle2/ItemTitle2";
import React from "react";

// List of usable item title and image components
const components = {
  ItemTitle,
  ItemImage1,
  ItemTitle1,
  ItemTitle2,
};

// Renders props item
const RenderItem = (props) => {
  if (props.isCat) {
    console.log(`props`, props);
  }
  const history = useHistory();

  const { setChosenCategory, setChosenItem, setisSerie } = useMyContext();

  const clickItem = (item) => {
    // Set chosenItem
    console.log(item);
    setChosenItem(item);

    // When item is clicked, set chosen category
    setChosenCategory({ id: item.groupItemIds, title: item.groups });

    // Push user to route, which is defined in main component

    // defining whether it is serie ir not

    props.isSerie ? setisSerie(true) : setisSerie(false);

    // Absolute or relative path -checker
    const urlRegExp = /^https?:\/\//i;
    if (urlRegExp.test(props.pushRoute)) {
      // Is absolute path, redirect user to location
      window.location.href = props.pushRoute;
    } else {
      // Is relative path, push to relative path
      history.push(props.pushRoute);
    }
  };

  // TODO: Pick image format based on config-file settings

  // Choose child component for image
  const ItemImageComponent = components[props.itemImageComponent];
  // Choose child component for itemTitle
  const ItemTitleComponent = components[props.itemTitleComponent];

  //console.log(props.item);
  return (
    props.item.name !== "placeholderItem" && (
      <div
        className={"categoryItem"}
        key={props.item.id}
        onClick={() => clickItem(props.item)}
      >
        <ItemImageComponent
          item={props.item}
          imageType={props.imageType}
          showHoverOverlay={props.showHoverOverlay}
          hidePlayButton={props.hidePlayButton}
          playIconSize="2x"
          extraClass={props.extraClass}
          // aspectRatio
          maintainImageAspectRatio={props.maintainImageAspectRatio}
        />
        {!props.hideTitleComponent ? (
          <ItemTitleComponent
            item={props.item}
            imageType={props.imageType}
            showCategoryName={props.showCategoryName}
            showReleaseYear={props.showReleaseYear}
            showDuration={props.showDuration}
            showStatus={props.showStatus}
            textStyle={props.textStyle}
            showPublishedDate={props.showPublishedDate}
            hideDescription={props.hideDescription}
          />
        ) : null}
      </div>
    )
  );
};

export default RenderItem;
