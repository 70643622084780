import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getImageByKey } from "../../../scripts/getImageByKey";
import { useCookies } from 'react-cookie';
import * as classes from './SignUpBar2.module.css';
import { validateEmail } from "../../../scripts/inputValidators";

// Renders signUpBar version 2
const SignUpBar2 = (props) => {

  const [signUpBarInput, setSignUpBarInput] = useState("");

  const [showInfoMessage, setShowInfoMessage] = useState(false);

  const [cookies, setCookie] = useCookies("");

  const history = useHistory();

  const { t } = useTranslation();

  const clickSignUp = () => {

    if (validateEmail(signUpBarInput)) {
      // Make sure info message is hidden
      setShowInfoMessage(false);

      // Set userToken in cookies
      setCookie("userData",
        {
          eMail: signUpBarInput,
        }, {
        path: '/',
        secure: true,
        sameSite: "none"
      });

      // Redirect user to signUp page
      history.push(`/${props.routes.signUp}`)
    } else {
      // Show info message about wrong password
      setShowInfoMessage(true);

      console.log(cookies);
    }
  };

  return (
    <div
      className={classes.SignUpBar}
    >
      <div
        className={classes.SignUpBarBackground}
      />

      <div className={classes.SignUpBarTitle}>
        {t("signUpBar.title")}
      </div>

      <div className={classes.SignUpBarSubtitle}>
        {t("signUpBar.subtitle")}
      </div>

      <div className={classes.SignUpBarText}>
        {t("signUpBar.welcomeText1")}
      </div>
      <div className={classes.SignUpContainer}>

        <input
          className={classes.SignUpBarSignUpInput}
          type="email"
          value={signUpBarInput}
          onChange={(e) => setSignUpBarInput(e.target.value)}
          placeholder={t("signUpBar.inputPlaceholderEmail")}
          onKeyPress={(e) => e.key === "Enter" ? clickSignUp() : null}
        />

        <button
          className={classes.SignUpBarSignUpBTN}
          type="submit"
          value="signUp"
          onClick={() => clickSignUp()}
        >
          {t("signUpBar.signupButton")}
        </button>

        <div
          className={classes.SignUpBarInfoMessage}
          style={showInfoMessage ? { display: "flex" } : { display: "none" }}
        >
          {t("signUpBar.errorIncorrectEmailAddress")}
        </div>
      </div>

    </div>
  );
}

export default SignUpBar2;
