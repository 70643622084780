import { NavLink } from 'react-router-dom';
import { getImageByKey } from '../../scripts/getImageByKey';
import { useTranslation } from 'react-i18next';
import * as classes from './NavBar.module.css';
import { CgMenu, CgClose } from 'react-icons/cg';
import { useState } from 'react';
import { useMyContext } from '../../contexts/StateHolder';

const NavBar = ({
  links,
  linksMobileLogin,
  linksMobileSearch,
  linksMobileLanguageSelect,
  linksMobileProfile,
}) => {
  const { t } = useTranslation();

  const {
    isMenu,
    setisMenu,
    closeHamMenu,
    isResponsiveclose,
    setResponsiveclose,
    user,
  } = useMyContext();
  // for main navigation mobile menu
  const toggleClass = () => {
    console.log(`toggle`, isMenu, isResponsiveclose);
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ['main-menu menu-right menuq1'];
  if (isMenu) {
    boxClass.push('menuq2');
  } else {
    boxClass.push('');
  }

  // for dropdown navigation mobile menu
  const [isMenuSubMenu, setMenuSubMenu] = useState(false);

  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };

  let boxClassSubMenu = ['sub__menus'];
  if (isMenuSubMenu) {
    boxClassSubMenu.push('sub__menus__Active');
  } else {
    boxClassSubMenu.push('');
  }

  return (
    <div className={classes.navBar}>
      {
        // site logo for big screen
        <NavLink to='/' exact activeClassName='no-class'>
          <img
            className='navBarSiteLogo'
            src={getImageByKey('siteLogo')}
            title={t('navBar.backToHome')}
            alt='SiteName'
            onClick={closeHamMenu}
          ></img>
        </NavLink>
      }

      {/* // hambuggermenu and close button on small screen */}

      <nav className={`${classes.navBarRow} main-nav `}>
        <div className='action'>
          {isResponsiveclose === true ? (
            <>
              <span
                className='menubar__button '
                style={{ display: 'none' }}
                onClick={toggleClass}
              >
                {' '}
                <CgClose />
              </span>
            </>
          ) : (
            <>
              <span
                className='menubar__button'
                style={{ display: 'none' }}
                onClick={toggleClass}
              >
                {' '}
                <CgMenu />
              </span>
            </>
          )}
          {/* // search will only appear when all links are not opened in navabar */}
          {!isResponsiveclose && (
            <div className={'navBarSiteLogoMobile'}>{linksMobileSearch}</div>
          )}
        </div>

        <ul className={` ${classes.navBarRow_primary} ${boxClass.join(' ')}`}>
          {links}
          {user.loggedIn && (
            <div className='navBarSiteLogoMobile'>{linksMobileProfile}</div>
          )}
          <div className='navBarSiteLogoMobile languageSelectMobile'>
            {linksMobileLanguageSelect}
          </div>
        </ul>

        <div className={'navBarSiteLogoMobile'}>
          <NavLink to='/' exact activeClassName='no-class'>
            <img
              src={getImageByKey('siteLogo')}
              title={t('navBar.backToHome')}
              alt='SiteName'
              onClick={closeHamMenu}
              className='navBarSiteLogoMobile-img'
            ></img>
          </NavLink>
        </div>

        <div className={'navBarSiteLogoMobile'}>{linksMobileLogin}</div>
      </nav>
    </div>
  );
};

export default NavBar;
