import React from 'react';
//import { getImageByKey } from '../../../../scripts/getImageByKey';
import * as classes from './BannerSingleAssetChildItem1.module.css';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RenderFolderNames from '../../../Details/DetailsFolderNames';
import DetailsItemDescription from '../../../Details/DetailsItemDescription';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

export default function BannerSingleAssetChildItem1(props) {

  const { t } = useTranslation();

  const history = useHistory();

  const clickAction = () => {
    if (props.item.pageUrl !== '') {
      // Absolute or relative path -checker
      const urlRegExp = /^https?:\/\//i;
      if (urlRegExp.test(props.item.pageUrl)) {
        // Is absolute path, redirect user to location
        window.location.href = props.item.pageUrl;
      } else {
        // Is relative path, push to relative path
        history.push(props.item.pageUrl);
      }
    } else if (props.item.isSerie) {
      // If episode of serie
      history.push(
        `/${props.routes.serieRoute}/${props.organizationId}/${props.item.id}`
      );
    } else if (props.item.series.length > 0) {
      // If main serie
      history.push(`/${props.routes.seriesCategories}/${props.organizationId}/${props.item.id}`);
    } else if (!props.item.isSerie) {
      // If video asset
      history.push(`/${props.routes.videoRoute}/${props.organizationId}/${props.item.id}`);
    } else {
      // Do nothing
    }
  }

  return (
    <div className={classes.itemContainer} onClick={props.onClick}>
      <div className={classes.imageContainer}>
        <img
          src={props?.item?.bannerImageSmall || props?.item?.thumbnailSmall}
          alt=''
          className={classes.itemImage}
        />
      </div>

      <div className={classes.itemDetailsContainer}>
        {props?.item?.name ?
          <div className={`${classes.itemTitle} font-600`}>
            {props.item.name}
          </div>
          : null}
        <div className={classes.folderYearRow}>

          {props.item.folders.length > 0 ?
            <div className={classes.itemCategories}>
              <RenderFolderNames
                item={props.item}
                routes={props.routes}
              />
            </div>
            : null}

          {props.item.releaseYear && props.item.releaseYear !== 0
            ? <div className={classes.itemReleaseYear}>{props.item.releaseYear}</div>
            : null}

        </div>


        {props?.item?.ingress || props?.item?.description || props?.item?.serie?.description ?
          <DetailsItemDescription
            // extraClassName='font-200'
            showAll={true}
            desc={
              props.item.ingress ||
              props.item?.description ||
              props.item?.serie?.description
            }
            size='1000000'
            mobileSize='1000000'
            sanitizeHTML={true}
            color="#484848"
          />
          : null
        }


        <div className={classes.actionButtonRow}>
          <button
            className={classes.first}
            onClick={() => clickAction()}
          >{t("singleAssetBanner.action")}
          </button>
        </div>
      </div>
    </div>
  );
}