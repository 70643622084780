import axios from "axios";
import { BASE_URL } from "./dataHandlers";

export const reactQuerygetSubCategories = async (
  organizationId,
  token,
  groupItemIds,
  languageId,
  user,
  assetProperty
) => {
  return axios.get(`${BASE_URL}/api/archive?action=getGroup`, {
    params: {
      organizationId,
      languageId,
      token,
      groupItemIds,
      showHidden: true,
      ...(user?.userId ? { userId: user.userId } : { userId: 0 }),
      ...(user?.userToken ? { userToken: user.userToken } : { userToken: "" }),
      // ...(assetProperty ? { assetProperty: assetProperty } : { assetProperty: "" }),
    },
  });
};

// will get root categories
export const reactQuerygetRootSubCategories = async (
  organizationId,
  key,
  languageId,
  groupItemId,
  user,
  source
) => {
  return await axios.get(`${BASE_URL}/api/archive?action=getGroups`, {
    cancelToken: source.token,
    params: {
      organizationId,
      languageId,
      groupItemId,
      token: key,
      ...(user?.userId ? { userId: user.userId } : { userId: 0 }),
      ...(user?.userToken ? { userToken: user.userToken } : { userToken: "" }),
    },
  });
};

export const reactQueryfetchCategoryAssets = (
  organizationId,
  groupItemId,
  token,
  languageId,
  assetProperty,
  user,
  showHidden = false
) => {
  return axios.get(
    `${BASE_URL}/api/publishing/getAssets?version=06&series=false&limit=30`,
    {
      params: {
        organizationId,
        languageId,
        groupItemId,
        token,
        showHidden,
        ...(user?.userId ? { userId: user?.userId } : {}),
        ...(user?.userToken ? { userToken: user?.userToken } : {}),
        ...(assetProperty ? { assetProperty: assetProperty } : {}),
      },
    }
  );
};

// Get banner items
export const reactQuerygetBanner = async (
  organizationId,
  groupItemId,
  token,
  languageId,
  user
) => {
  return axios.get(`${BASE_URL}/api/publishing?action=getBanners&version=05`, {
    params: {
      organizationId,
      languageId,
      token,
      groupItemId,
      series: false,
      ...(user?.userId ? { userId: user.userId } : { userId: 0 }),
      ...(user?.userToken ? { userToken: user.userToken } : { userToken: "" }),
    },
  });
};

export const reactQueryGetPromo = async (
  organizationId,
  groupItemId,
  token,
  languageId,
  assetProperty,
  user
) => {
  return axios.get(`${BASE_URL}/api/publishing?action=getAssets&version=05`, {
    params: {
      organizationId,
      languageId,
      token,
      groupItemId,
      series: false,
      orderBy: "publishStart",
      limit: 3,
      ...(user?.userId ? { userId: user.userId } : { userId: 0 }),
      ...(user?.userToken ? { userToken: user.userToken } : { userToken: "" }),
      ...(assetProperty
        ? { assetProperty: assetProperty }
        : { assetProperty: "" }),
    },
  });
};
