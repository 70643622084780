import * as classes from './PrivacyTerms.module.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const PrivacyTerms = (props) => {
  const { t } = useTranslation();

  return (
    <div className={`${classes.privacyTermsContainer} font-300`}>
      <div className={`${classes.privacyTermsTitle} font-600`}>{t('privacyTerms.title')}:</div>

      Tietosuojaseloste voimassa 1.3.2022 alkaen.

      <br />
      <br />
      Kotikino kunnioittaa ja suojelee verkkosivuillaan vierailevien henkilöiden, asiakkaidensa sekä sidosryhmätyössä toimivien henkilöiden yksityisyyttä. Tältä sivulta löydät tietosuojaperiaatteemme, sivujemme käyttöehdot ja rekisteriselosteet.
      <div className={classes.item}>
        <div className={`${classes.privacyTermsTitle} font-400`}>
          Tietosuojaperiaatteemme
        </div>
        <br />
        Keräämme ja käsittelemme vain tarpeellista tietoa ja poistamme vanhentuneet tiedot. Käytämme henkilötietoja viestinnässä, asiakaspalvelussa ja palveluiden toimittamisessa.
        <br />
        <br />
        Henkilötietoja käsitellään keskitetysti Nordic Content Distribution Oy:n toimistolla Helsingissä.
        <br />
        <br />
        Henkilötietoja käytetään myös luvallisen markkinoinnin sekä mainonnan kohdentamiseen Emme lähtökohtaisesti luovuta henkilötietoja yrityksen ulkopuolelle
        <br />
        <br />
        Tarkempaa tietoa henkilötietojen käsittelystä ja käyttämisestä sekä omista oikeuksistasi löydät alla olevista henkilötietorekisteriselosteista.<br />
      </div>

      <div className={classes.item}>
        <div className={`${classes.privacyTermsTitle} font-400`}>
          Evästeet
        </div>
        <br />
        Vieraillessasi Kotikino -nettisivuilla, tallentaa nettiselaimesi laitteesi kovalevylle automaattisesti evästeitä. Eväste voi sisältää tekstiä, numeroita, päivämääriä, paikkatietoja, ja muuta dataa mutta evästeisiin ei tallennu mitään henkilökohtaisia tietoja. Eväste ei myöskään ole sovellus, eikä sen avulla voi koneeseesi päästä viruksia tai muita haittaohjelmia. Evästeistä ei ole koneellesi minkäänlaista haittaa.
        <br />
        <br />
        Evästeiden avulla voidaan selvittää sivuston kävijämääriä, tallentaa sivustolla tehtyjä valintoja (esim. kieliasetukset), seurata kuinka sivustoa käytetään (ns. klikkauspolut) sekä kohdentaa ja hallita mainontaa (esim. ei näytetä samoja mainoksia useaan kertaan). Emme kuitenkaan seuraa yksittäisen kävijän tietoja, vaan tilastoimme kävijätietoja esim. käyttäytymisen ja maantieteellisen sijainnin perusteella.
        <br />
        <br />
        Evästeiden avulla pyrimme parantamaan käyttökokemusta sivuillamme sekä tuomaan sivuille mainontaa joka käyttäjää kiinnostaa. Voit esimerkiksi nähdä muilla sivuilla vieraillessasi Kotikinon mainontaa. Tämä on mahdollista evästeiden avulla.
        <br />
        <br />
        Mikäli Kotikinon sivuilla vieraileva kävijä ei halua Kotikinon edellä mainittuja tietoja evästeiden avulla, voi evästeiden käytön kieltää selaimen asetuksista.
      </div>

      <div className={classes.item}>
        <div className={`${classes.privacyTermsTitle} font-400`}>
          Markkinointirekisteriseloste
        </div>
        <br />
        Yhdistetty rekisteriseloste- ja informointiasiakirja
        <br />
        <br />
        <div className={classes.bolded}>1. Rekisterinpitäjä</div>
        <br />
        Nordic Content Distribution Oy Vanha talvitie 11 A 00520 Helsinki Puhelin: 010 341 1000 Sähköposti: <a href={'mailto:tuki@kotikino.fi'}>tuki@kotikino.fi</a>
        <br />
        <br />
        <div className={classes.bolded}>2. Yhteystiedot henkilötietojen käsittelyä koskevissa asioissa</div>
        <br />
        Vanha talvitie 11 A
        <br />
        00520 Helsinki
        <br />
        Sähköposti: <a href={'mailto:tuki@kotikino.fi'}>tuki@kotikino.fi</a>
        <br />
        <br />
        <div className={classes.bolded}>3. Rekisterin nimi</div>
        <br />
        Kotikinon tiedotus- ja markkinointirekisteri.
        <br />
        <br />
        <div className={classes.bolded}>4. Henkilötietojen käsittelyn laillinen peruste ja tarkoitus</div>
        <br />
        Henkilötietolain 8 §:n ja EU:n tietosuoja-asetuksen 2 luvun mukaiset yleiset edellytykset. Henkilötietojen käsittelyn tarkoitus on viestiä Kotikino palvelusta ja uusista sisällöistä. Tarvittavien henkilötietojen käsittely on välttämätön edellytys tarkoituksen toteuttamiseksi. Henkilötietojen käsittelyn perusteena on suostumus.
        <br />
        <br />
        Tietojen tyypillisimmät käyttöalueet ovat:
        <br />
        <br />
        palvelujen kehittäminen, suoramarkkinointi, lahjoituspyynnöt, tapahtumakutsut, digimarkkinointi ja etämyynti, asiakas- ja mielipidetutkimukset.
        <br />
        <br />
        Kotikino säilyttää vain palvelun toiminnan ja tietojen käyttötarkoitusten kannalta tarpeellisia tietoja, joiden käsittelylle on lailliset edellytykset. Tarpeettomaksi muuttuneet tiedot ja tiedot, joiden käsittelylle ei enää muutoin ole perustetta, anonymisoidaan tai hävitetään tietoturvallisesti.
        <br />
        <br />
        Henkilötietoja voidaan käsitellä soveltuvan lainsäädännön sallimin tavoin kolmansilla osapuolilla, mukaan lukien markkinointi, etämyynti ja markkina- sekä mielipidetutkimukset. Kolmannet osapuolet voivat olla mainostoimistoja, painotaloja tai vastaavia yhteistyökumppaneita, jotka tukevat rekisterin tarkoituksen toteutumista.
        <br />
        <br />
        Kotikinolla on asianmukaiset sopimukset tällaisten kolmansien tahojen kanssa henkilötietojen käsittelyä varten.
        <br />
        <br />
        <div className={classes.bolded}> 5. Rekisterin tietosisältö </div>
        <br />
        Markkinointirekisterissä voidaan käsitellä seuraavia tietoja:
        <br />
        <br />
        · Nimitiedot
        <br />
        · Yhteystiedot (sähköposti)
        <br />
        · Osoitelähde (mitä kautta osoite on tullut rekisteriin)
        <br />
        · Yhteydenottojen historiatiedot
        <br />
        <br />
        <div className={classes.bolded}>6. Säännönmukaiset tietolähteet</div>
        <br />
        Markkinointirekisterin henkilötietoja kerätään eri kanavien kautta yhteystietonsa Kotikinolle antaneista henkilöistä (esim. uutiskirjeen tilaus, kilpailun/ arvontakortin täyttäminen).
        <br />
        <br />
        Välttämättömien tietojen antaminen on edellä mainituissa markkinoinnin vastaanottamisen edellytys.
        <br />
        <br />
        Rekisterissä ei tehdä automaattisia päätöksiä.
        <br />
        <br />
        <div className={classes.bolded}>7. Säännönmukaiset tietojen luovutukset</div>
        <br />
        Yritys ei luovuta rekisterissään olevien tietoja ulkopuolisille muihin kuin tässä rekisteriselosteessa mainittujen tarkoitusten toteuttamiseksi ja lakisääteisten tehtäviensä täyttämiseksi tai oikeuksiensa toteuttamiseksi ilman asianomaisen nimenomaista ja tietoista suostumusta.
        <br />
        <br />
        Rekisteröityjen henkilötietoja voidaan käsitellä Kotikinon markkinoinnin ja etämyynnin toteuttamiseen osallistuvilla yhteistyökumppaneilla (esim. postitusfirma) tai mahdollista asiakas- tai mielipidetutkimusta varten. Kotikinolla on asianmukaiset sopimukset tällaisten kolmansien tahojen kanssa henkilötietojen käsittelyä varten
        <br />
        <br />
        <div className={classes.bolded}>8. Tietojen siirto EU:n tai Euroopan talousalueen ulkopuolelle</div>
        <br />
        Kotikino ei luovuta lahjoittaja- ja sidosryhmärekisterissään olevia tietoja EU:n tai ETA:n ulkopuolelle.
        <br />
        <br />
        <div className={classes.bolded}>9. Rekisterin suojauksen periaatteet</div>
        <br />
        Markkinointirekisteriä ylläpidetään sähköisessä muodossa Icareus Suite -järjestelmässä sekä pieniltä osin verkkolevyllä Excel-tiedostoina. Järjestelmien tietokannat ja ohjelmistot ovat palveluntuottajan palvelimilla Internetissä, johon työasemilta on käyttäjä- ja salasanasuojauksin suojattu käyttöliittymä.
        <br />
        <br />
        Rekisterin käyttö edellyttää henkilökohtaista käyttäjätunnusta ja salasanaa, jonka järjestelmä pakottaa vaihtamaan määräajoin. Käyttöoikeus päättyy henkilön siirtyessä pois niistä tehtävistä, joita varten hänelle on myönnetty käyttöoikeus. Samalla lukitaan käyttäjätunnus.
        <br />
        <br />
        Henkilötietoja käsitellään luottamuksellisesti. Rekisterinpitäjä on järjestänyt tiloihinsa kulunvalvonnan.
        <br />
        <br />
        <div className={classes.bolded}>10. Tarkastusoikeus ja tietojen korjaaminen </div>
        <br />
        Rekisteröidyllä on oikeus tarkistaa Kotikinon rekisterissä olevat itseään koskevat tiedot ja pyytää virheellisten tietojen korjaamista. Rekisteröity voi esittää tarkastusoikeutta koskevan pyynnön henkilökohtaisesti tai kirjallisesti kohdassa 2 mainittuihin yhteystietoihin. Tarkastusoikeus toteutetaan viikon kuluessa pyynnön esittämisestä.
        <br />
        <br />
        Rekisteriin ei sisälly tietoja, joiden osalta tarkastusoikeutta ei voida toteuttaa. Rekisteröity voi esittää tiedon korjaamista milloin tahansa rekisteriasioista vastaavalle henkilölle.
        <br />
        <br />
        Tietoja voidaan oikaista tai täydentää rekisterinpitäjän toimesta tai rekisteröidyn vaatimuksesta. Rekisteröidyllä on oikeus vaatia tietojen käsittelyn rajoittamista ja vastustaa tietojen käsittelyä.
        <br />
        <br />
        Kotikinon rekisterissä olevalla henkilöllä on oikeus kieltää antamiensa tietojen käytön suoramarkkinointi- tms. tarkoitukseen, muuttaa niiden julkaisemista koskevaa suostumustaan sekä muutoinkin turvautua henkilötietolaissa turvattuihin oikeuksiinsa. Mikäli henkilö ei toivo Kotikinoltä lainkaan postia tai yhteydenottoja, hän voi kieltää sen ottamalla kohdassa 2 mainittuun osoitteeseen.
        <br />
        <br />
        Kotikino: poistaa tarpeettomat henkilötiedot rekisteristä rekisteröidyn pyynnöstä. Rekisteröidyllä on oikeus tehdä valitus tietosuojaviranomaiselle.
        <br />
        <br />
        <div className={classes.bolded}>11. Tietojen säilyttäminen</div>
        <br />
        Tietoja säilytetään toistaiseksi, niin kauan kuin ne ovat tarpeellisia. Tarpeettomaksi muuttuneet tiedot ja tiedot, joiden käsittelylle ei enää muutoin ole perustetta, hävitetään tietoturvallisesti.
      </div>
    </div>
  );
};

export default PrivacyTerms;