export const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "500px",
        background: "#1e2228",

        border: "none",
        maxWidth: "700px",
    },
};
