import { useMyContext } from "../../../contexts/StateHolder";
import RenderItem from "../RenderItems/RenderItem";
import CategoryTitle from "../CategoryTitles/CategoryTitle";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createToken } from "../../../scripts/utils";
import * as classes from "./ChosenCategoryGrid.module.css";
import CategoryTitle2 from "../CategoryTitles/CategoryTitle2/CategoryTitle2";
import { getUniqueSerieAndAssets } from "../../../lib/getUniqueSerieAndAssets";
import { getAssetPushRoute } from "../../../lib/getAssetPushRoute";
import { useQuery } from "react-query";
import {
  reactQueryfetchCategoryAssets,
  reactQuerygetSubCategories,
} from "../../../scripts/dataHandlerReactquery";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ChosenCategoryGrid = (props) => {
  // List of usable category components
  const components = {
    CategoryTitle,
    CategoryTitle2,
  };

  // Destructure props.settings
  const {
    routes,
    assetProperty,
    categoryTitleComponent,
    itemImageComponent,
    itemTitleComponent,
    imageType,
    maintainImageAspectRatio,
    preDefinedCategory,
  } = props.settings;

  const { chosenCategory, key, language, organizationId, user } =
    useMyContext();

  const [categoryName, setCategoryName] = useState([]);

  const { asset } = useParams();

  const fetchCategoryDetail = () => {
    let groupItemId =
      preDefinedCategory && preDefinedCategory !== ""
        ? preDefinedCategory
        : asset;
    const token = createToken(organizationId, groupItemId, key);

    return reactQuerygetSubCategories(
      organizationId,
      token,

      groupItemId,
      language,
      user,
      assetProperty
    );
  };

  const { data: categoryData } = useQuery(
    [
      `$getGridCategory${
        preDefinedCategory && preDefinedCategory !== ""
          ? preDefinedCategory
          : asset
      }`,

      // params for triggering refetch of API
      asset,
      chosenCategory,
    ],
    fetchCategoryDetail,

    {
      // by default the cache time is 5 minutes
      cacheTime: 300000,
      staleTime: Infinity,
      select: (res) => {
        return getUniqueSerieAndAssets(res?.data?.data);
      },
    }
  );

  const fetchAssets = () => {
    let groupItemId =
      preDefinedCategory && preDefinedCategory !== ""
        ? preDefinedCategory
        : asset;
    const token = createToken(organizationId, groupItemId, key);

    return reactQueryfetchCategoryAssets(
      organizationId,
      groupItemId,
      token,
      language,
      assetProperty,
      user
    );
  };

  // need to pass unique key to react use query

  const { data: assets, isLoading } = useQuery(
    [
      `$getGrid${
        preDefinedCategory && preDefinedCategory !== ""
          ? preDefinedCategory
          : asset
      }`,
      // params for triggering refetch of API
      asset,
      chosenCategory,
    ],
    fetchAssets,

    {
      // by default the cache time is 5 minutes
      cacheTime: 300000,
      staleTime: Infinity,
      select: (res) => {
        return getUniqueSerieAndAssets(res?.data?.assets);
      },
    }
  );

  console.log("assets", categoryData);

  useEffect(() => {
    if (categoryData?.[0]) {
      setCategoryName(categoryData[0]?.title);
    }
  }, [categoryData]);

  const renderChosenCategoryGrid = () => {
    return assets?.map((item, i) => {
      let pushRoute = "";

      pushRoute = getAssetPushRoute(item, routes, organizationId);

      return (
        <div key={i}>
          <RenderItem
            key={i}
            item={item}
            routes={routes}
            itemImageComponent={itemImageComponent}
            itemTitleComponent={itemTitleComponent}
            pushRoute={pushRoute}
            imageType={imageType}
            showCategoryName={true}
            showHoverOverlay={true}
            // whether to maintain aspect ratio 16/9
            maintainImageAspectRatio={maintainImageAspectRatio}
            showDuration={true}
            showReleaseYear={true}
            hideDescription={true}
          />
        </div>
      );
    });
  };
  const skeletonItem = [...Array(30).keys()];

  const RenderLoading = () => {
    return skeletonItem.map((el2) => (
      <SkeletonTheme key={el2}>
        <p>
          <Skeleton
            key={el2}
            width={"95%"}
            height={"260px"}
            style={{
              borderRadius: "16px",
            }}
          />
        </p>
      </SkeletonTheme>
    ));
  };

  // Choose child component for title
  const TitleComponent = components[categoryTitleComponent];

  return (
    <div className={classes.chosenCategoryGrid}>
      <TitleComponent
        title={categoryName || chosenCategory.title}
        styles={{
          justifyContent: "center",
        }}
        // for Category2
        item={chosenCategory}
        extraClassname={true}
      />

      <div className={classes.ViewedSectionSecondary__3videos}>
        {!isLoading ? renderChosenCategoryGrid() : <RenderLoading />}
      </div>
    </div>
  );
};

export default ChosenCategoryGrid;
