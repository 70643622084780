import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { createAssetIdToken } from "../../scripts/utils";
import { getImageByKey } from "../../scripts/getImageByKey";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../contexts/StateHolder";
import axios from "axios";
import { getAsset } from "../../scripts/dataHandlers";

//const { REACT_APP_RADIANT_LICENSE } = process.env;

export default function RadiantPlayer(props) {
    const { chosenItem, user, language, setChosenItem } = useMyContext();
    console.log(props, user);

    console.log(props);
    const rmpPlayer = useRef();

    const history = useHistory();
    const [radiantMediaPlayerObject, setRadiantMediaPlayerObject] = useState(null);

    // Setup translate function
    const { t } = useTranslation();

    useEffect(() => {
        let radiantscript, vodjs, advertjs;

        if (chosenItem && props.isSerie && !chosenItem.serie && props.itemId) {
            // finding ffolder name and id of series asset basically for google analytics
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const fetchChosenItem = async () => {
                const token5 = createAssetIdToken(props.organizationId, props.itemId, language, props.secretKey);

                const res = await getAsset(props.organizationId, props.itemId, token5, language, "", user, source);
                console.log(res, "res assets");
                setChosenItem(res);
            };
            fetchChosenItem();
        }

        if (props.secretKey && props.itemId) {
            const createScript = () => {
                radiantscript = document.createElement("script");
                vodjs = document.createElement("script");
                advertjs = document.createElement("script");
                vodjs.id = "vodjs";

                radiantscript.src = "https://cdn.radiantmediatechs.com/rmp/8.4.10/js/rmp.min.js";
                radiantscript.async = true;

                vodjs.async = true;
                vodjs.src = "https://staging1.icareus.com/lib/js/players/v4/players/vodUpdated.js";

                advertjs.async = true;
                advertjs.src = "https://staging1.icareus.com/lib/js/players/v3/players/adverts.js";

                document.body.appendChild(radiantscript);
                document.body.appendChild(vodjs);
                document.body.appendChild(advertjs);
            };
            createScript();

            window._icareus = {};

            window._icareus.companyId = props.companyId;

            window._icareus.groupId = props.groupId;

            window._icareus.organizationId = props.organizationId;

            window._icareus.itemId = props.itemId;

            window._icareus.host = "https://suite.icareus.com";
            window._icareus.playerId = "rmpPlayer";
            window._icareus.playerType = "radiant";
            window._icareus.playerSetup = "vodJSCallback";
            window._icareus.playerAutoStart = true;
            window._icareus.userId = user?.userId;

            // VOD ADVERT
            window._icareus.campaignId = props.campaignIDD;
            window._icareus.useAdvertsLibrary = props.campaignIDD ? true : false;
            window._icareus.advertVOD = props.campaignIDD ? true : false;
            // default 30
            window._icareus.applicationTypeId = 1;
            window._icareus.applicationId = 1;

            window._icareus.token = createAssetIdToken(props.organizationId, props.itemId, false, props.secretKey);
            window._icareus.initializedByReactApps = true;

            // google analytics as instructed by digital starts here only if tracking id provided
            if (chosenItem && props.googleTrackingId) {
                window._icareus.gaLabel = ` ${chosenItem?.name ? chosenItem.name.toUpperCase() : chosenItem.title.toUpperCase()}[${props.itemId}]`;
                window._icareus.gaCategory = props.isSerie ? "Series" : "Videos";
                window._icareus.gatrackingId = props.googleTrackingId || "UA-82995370-2";
                window._icareus.isSerie = props.isSerie ? props.isSerie : false;
                window._icareus.gaFolderLabel = props.isSerie
                    ? `${chosenItem.serie?.title}[${chosenItem.serie?.id}]`
                    : chosenItem.folders && chosenItem.folders.length > 0
                    ? `${chosenItem.folders[0].name}/${chosenItem.folders.id}`
                    : "Demo Title For Videos";
                window._icareus.gaFolderCategory = "Folder";
            }
            // google analytics as instructed by digital ends here

            const backButtonHandler = () => {
                if (props.backRoute === undefined) {
                    history.goBack();
                } else if (props.backRoute === "hidden") {
                    props.setShowPlayer("wantedHidden");
                } else {
                    history.push(props.backRoute);
                }
                //props.backRoute ? history.push(props.backRoute) :
            };

            // Then we define a custom module - in this case a 10s rewind module
            const backCustomModule = {
                hint: t("videoPlayer.close"), // Then name of the module - will show as hint within player UI
                svg: "https://cdn.radiantmediatechs.com/rmp/svg/close.svg", // Then SVG icon for the module
                // svgHover: "https://cdn.radiantmediatechs.com/rmp/svg/close_hover.svg", // TODO: Image from /images, not web url
                // An optional second icon that can be displayed when the module is hovered
                callback: function () {
                    // Our callback function
                    backButtonHandler();
                },
            };

            let settings;
            console.log(window.location.hostname);

            window.vodJSCallback = function () {
                // Then we set our player settings
                settings = {
                    detectViewerLanguage: false,

                    licenseKey: window.location.hostname.includes("icareus.com")
                        ? `Kl8lc3k9b3Y4MDJ5ZWk/cm9tNWRhc2lzMzBkYjBBJV8q`
                        : props.license || "Kl8lc3k9b3Y4MDJ5ZWk/cm9tNWRhc2lzMzBkYjBBJV8q",
                    src: window._icareus.sources,
                    ccFiles: window._icareus.subtitles,

                    // height and width selected based on 16/9 aspect ration
                    autoHeightMode: true,
                    autoHeightModeRatio: 1.7777777778,
                    skin: props.skin ? props.skin : "s1",

                    skinBackgroundColor: props.skinBackgroundColor ? props.skinBackgroundColor : "rgba(33, 33, 33, 0.85)",
                    skinButtonColor: "rgba(255, 255, 255, 1)",
                    skinAccentColor: "rgba(130, 177, 255, 1)",

                    // this is for playback speed
                    speed: props.speed ? true : false,
                    automaticFullscreenOnLandscape: true,
                    // ads: props.campaignIDD ? true : false,
                    ads: false,
                    adTagUrl: window._icareus.videoVastURL,
                    gaTrackingId: window._icareus.playerObject.gaTrackingId,

                    contentMetadata: {
                        title: props.title ? props.title : null,
                        description: props.description ? props.description : null,
                        poster: [props.poster ? props.poster : window._icareus.thumbnail],
                    },

                    ...(props.backButton ? { customModule: [{ ...backCustomModule }] } : {}),

                    preload: "auto",
                    asyncElementID: rmpPlayer.current.id,
                };
                console.log(settings);
                console.log(window._icareus);

                window._icareus.playerObject.init({ ...settings });
                console.log(window._icareus);
                setRadiantMediaPlayerObject(window._icareus.playerObject);

                // functionOne(() => {});
            };
            console.log(window);
            console.log(window._icareus);

            return () => {
                // pausing the rmp
                radiantMediaPlayerObject?.pause();
                // muting the rmp
                radiantMediaPlayerObject?.setMute(true);
                // destroying the rmp
                radiantMediaPlayerObject?.destroy();
                document.body.removeChild(radiantscript);
                document.body.removeChild(vodjs);
                document.body.removeChild(advertjs);
                setRadiantMediaPlayerObject(null);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        // you may set the width even in the parent component to make these values as variables
        <div ref={rmpPlayer} id="rmpPlayer" className={props.fullBrowser ? "rmpPlayerMax" : ""}></div>
    );
}
