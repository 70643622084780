import React from "react";
import Carousel from "react-multi-carousel";
import useWindowDimensions from "../../components/WindowDimension";

import classes from "./PromoBannerCustomCarousel.module.css";

const slickSettings = {
  infinite: false,

  responsive: {
    // desktop: {
    //   breakpoint: {
    //     max: 4000,
    //     min: 1441,
    //   },
    //   items: 1,
    //   partialVisibilityGutter: 0,
    //   slidesToSlide: 1,
    // },
    // laptop: {
    //   breakpoint: {
    //     max: 1440,
    //     min: 969,
    //   },
    //   items: 3,
    //   partialVisibilityGutter: 0,
    //   slidesToSlide: 1,
    // },
    // laptopMini: {
    //   breakpoint: {
    //     max: 968,
    //     min: 769,
    //   },
    //   items: 1,
    //   partialVisibilityGutter: 100,
    //   slidesToSlide: 1,
    // },

    // tablet: {
    //   breakpoint: {
    //     max: 768,
    //     min: 550,
    //   },
    //   items: 1,
    //   partialVisibilityGutter: 100,
    //   slidesToSlide: 1,
    // },
    // mobile: {
    //   breakpoint: {
    //     max: 549,
    //     min: 0,
    //   },
    //   items: 1,
    //   partialVisibilityGutter: 40,
    //   slidesToSlide: 1,
    // },

    // new
    // laptop: {
    //   breakpoint: {
    //     max: 4000,
    //     min: 969,
    //   },
    //   items: 3,
    //   partialVisibilityGutter: 0,
    //   slidesToSlide: 0,
    // },
    // laptopMini: {
    //   breakpoint: {
    //     max: 968,
    //     min: 769,
    //   },
    //   items: 2,
    //   partialVisibilityGutter: 100,
    //   slidesToSlide: 1,
    // },

    // tablet: {
    //   breakpoint: {
    //     max: 768,
    //     min: 550,
    //   },
    //   items: 2,
    //   partialVisibilityGutter: 100,
    //   slidesToSlide: 1,
    // },
    // mobile: {
    //   breakpoint: {
    //     max: 549,
    //     min: 0,
    //   },
    //   items: 1,
    //   partialVisibilityGutter: 40,
    //   slidesToSlide: 1,
    // },
    desktop: {
      breakpoint: {
        max: 4000,
        min: 1441,
      },
      items: 1,
      partialVisibilityGutter: 0,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: {
        max: 1440,
        min: 550,
      },
      items: 3,
      partialVisibilityGutter: 0,
      slidesToSlide: 1,
    },

    mobile: {
      breakpoint: {
        max: 549,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 40,
      slidesToSlide: 1,
    },
  },
};

const PromoBannerCustomCarousel = ({ children }) => {
  const { width } = useWindowDimensions();
  return (
    <Carousel
      {...slickSettings}
      // centermode and partial mode needs to be other way araound. both cannot be true
      centerMode={width > 968 ? true : false}
      itemClass={classes["promo-carousel-item-padding-10-px"]}
      containerClass={classes["promo-container"]}
    >
      {children}
    </Carousel>
  );
};

export default PromoBannerCustomCarousel;
