import { useMyContext } from "../../../contexts/StateHolder";
import SingleCateoryAssets from "./SingleCateoryAssets";

const AssetsMode2 = (props) => {
  // mode2 gets all categories from organization and gets assets -< in our initial request(context) we already have allCat

  // mode 2: read orgId from request or from config file, and list all top level categories of that organisation

  const { allCategories } = useMyContext();

  return (
    <>
      {allCategories?.length > 0 &&
        allCategories.map((el, i) => (
          <SingleCateoryAssets
            category={el}
            {...props}
            key={el?.id || i.toString()}
          />
        ))}
    </>
  );
};

export default AssetsMode2;
