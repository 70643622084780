import React from "react";
import { useHistory } from "react-router-dom";

import settings from "../../../configs/config_settings.json";

import PromoBannerCustomCarousel from "../../../Shared/CustomCarousel/PromoCustomCarousel";
import classes from "./PromoItem.module.css";
import { getAssetPushRoute } from "../../../lib/getAssetPushRoute";

const PromoIte = ({ assets, routes }) => {
  const { organizationId } = settings.organization;
  const history = useHistory();

  return (
    assets?.length > 0 && (
      <PromoBannerCustomCarousel>
        {assets.map((el) => {
          let pushRoute = getAssetPushRoute(el, routes, organizationId);

          return (
            <div className={classes.promoContainer} key={el?.id}>
              <img
                src={
                  el?.thumbnailSmall ||
                  el?.thumbnailMedium ||
                  el?.thumbnailLarge ||
                  el?.bannerImageSmall ||
                  el?.bannerImageMedium ||
                  el?.bannerImageLarge ||
                  "https://static.mana2.my/image/image_gallery?img_id=226425044"
                }
                key={el?.id}
                className={`${classes.promoImage} img-link`}
                alt={"home-banner"}
                onClick={() => {
                  history.push(pushRoute);
                }}
              />
            </div>
          );
        })}
      </PromoBannerCustomCarousel>
    )
  );
};

export default PromoIte;
