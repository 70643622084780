import "./App.css";
import "react-loading-skeleton/dist/skeleton.css";

import NavBar from "./components/NavBars/NavBar";

import DetailsVideo2 from "./components/Details/DetailsVideos/DetailsVideo2/DetailsVideo2";
import DetailsVideo from "./components/Details/DetailsVideos/DetailsVideo/DetailsVideo";
//import DetailsSerie from './components/Details/DetailsSerie';
import FAQ from "./components/FAQ/FAQ";
import TOS from "./components/TOS/TOS";

import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useMyContext } from "./contexts/StateHolder";
import BottomBar from "./components/Footers/BottomBar";
import Profile from "./components/Profile/Profile";
import settings from "./configs/config_settings.json";
import Banner from "./components/ViewAssets/BannersSlider/Banner";
import Promo from "./components/ViewAssets/Promos/Promo";
import Search from "./components/Search/Search";
import { useTranslation } from "react-i18next";

import ChosenCategoryGrid from "./components/ViewAssets/Grids/ChosenCategoryGrid";

import { getCategories, getChannelStatus } from "./scripts/dataHandlers";
//import RenderCategoryDropdown from './components/DropDowns/CategoryDropdown';
import LoginBTN from "./components/Login/LoginBTN";
import NavBarButton from "./components/NavBars/NavBarButton";
import AssetVideoPlayer from "./components/VideoPlayer/AssetVideoPlayer";
import SignUpForm from "./components/SignUp/SignUpForm/SignUpForm";
import LoginForm from "./components/Login/LoginForm";
import { useCookies } from "react-cookie";
import "./Shared/FontAwesomeIcon";
//import ListCategories from './components/Categories/ListCategories';
import SignUpBar2 from "./components/SignUp/SignUpBar2/SignUpBar2";
import * as classes from "./components/NavBars/NavBar.module.css";
import ContactUs from "./components/ContactUs/ContactUs";
import GuidesBar from "./components/Others/GuidesBar/GuidesBar";
import EpisodesWithSerieTitles from "./components/ViewAssets/Sliders/Series/EpisodesWithSerieTitles/EpisodesWithSerieTitles";
import TrendingTopShows from "./components/ViewAssets/Sliders/Series/TrendingTopShows/TrendingTopShows";
import GetLatestOrTrendingAssets from "./components/ViewAssets/Sliders/GetLatestOrTrendingAssets/GetLatestOrTrendingAssets";
import PrivacyTerms from "./components/PrivacyTerms/PrivacyTerms";

import BannerSingleAsset from "./components/ViewAssets/BannersSingleAsset/BannerSingleAsset";
import DetailsSeasonsAndEpisodes from "./components/Details/DetailsSeasonsAndEpisodes";
// new slick

import "react-multi-carousel/lib/styles.css";

// Packages for buying
import PurchaseFlow from "./components/Packages/PurchaseFlow";
import LoggedInBox from "./components/Packages/LoggedInBox/LoggedInBox";

// toastify
import "react-toastify/dist/ReactToastify.css";

// Google Analytics
import ReactGA from "react-ga";

import Socials from "./components/EmbedPlayer/EmbedPlayer";
import HelmetMetaData from "./components/ShareSocialMedia/HelmetMetaData";
import Spinner from "./components/Spinner/Spinner";
import RequestResetPassword from "./pages/RequestResetPassword";
import Assets from "./pages/Assets";
import NavBarCategoryDropDown from "./components/NavBars/NavBarCategoryDropdown";
import Promo1 from "./components/ViewAssets/Promos/PromoItem";

if (settings.googleAnalytics !== "") {
  // Initialize Google analytics with API-key from .env file
  ReactGA.initialize(settings.googleAnalytics);
}

function App() {
  // Bring stateholders from context
  const {
    setAllCategories,

    key,
    setKey,

    organizationId,
    setOrganizationId,

    language,
    setLanguage,

    user,
    setUser,
    //liveChannelPrivate,
    setLiveChannelPrivate,
    //allLanguages,
    setAllLanguages,
    isResponsiveclose,
    chosenItem,
    userLoggedIn,
  } = useMyContext();

  const location = useLocation();

  const { t } = useTranslation();

  const [cookies] = useCookies("");
  const [loading, setLoading] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);

  // UseEffect that will re-trigger on every pagechange, sending current location to Google Analytics
  useEffect(() => {
    if (settings.googleAnalytics !== "") {
      // Report Google Analytics about user entering this page
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [location]);

  /**** STEP 1, FIRST TIME INIT PROGRAM ****/
  useEffect(() => {
    async function initProgram() {
      try {
        // Set organizationId to context
        setOrganizationId(settings.organization.organizationId);

        // Set key to context
        setKey(settings.organization.key);

        // Set language from config and set it to context
        setLanguage(settings.language);

        setAllLanguages(
          Object.keys(settings.languages).map((k) => settings.languages[k])
        );
      } catch (err) {
        console.log(err);
      }
    }

    initProgram();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log('cookies', cookies);
  useEffect(() => {
    if (cookies?.userData?.userToken || userLoggedIn) {
      if (!user?.loggedIn && cookies?.userData?.userToken) {
        console.log(user);
        let modifiedUser = { ...user };

        // we will fill data from cookie if it is not there

        modifiedUser.loggedIn = true;

        modifiedUser.userId = cookies?.userData?.userId;

        modifiedUser.userToken = cookies?.userData?.userToken;

        modifiedUser.firstName = cookies?.userData?.firstName;

        modifiedUser.organizationId = cookies?.userData?.userOrganizationId;
        modifiedUser.eMail = cookies?.ue;
        modifiedUser.organizationName = cookies?.userData?.userOrganizationName;

        setUser(modifiedUser);
      }

      // getiing package of parent org ->- we need to match these later to check the event packages

      setAuthLoaded(true);
    } else {
      setAuthLoaded(true);
    }
  }, [cookies?.userData?.userToken, userLoggedIn]);

  // channel status (private or public)

  console.log(window.location.hostname);

  /**** STEP 2, CHECK LANGUAGE AND GET CATEGORIES (STEP WILL BE REPEATED IF LANGUAGE CHANGES) ****/
  useEffect(() => {
    async function getAndSetCategories() {
      try {
        // Get categories from datahandler
        const categoryList = await getCategories(
          organizationId,
          key,
          language,
          user
        );

        // Set categories in context
        setAllCategories(categoryList);
      } catch (err) {
        console.log(err);
      }
    }

    // Get categories only if language is set
    if (language !== "") {
      getAndSetCategories();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return !loading && authLoaded ? (
    <div className="App">
      <div className="container">
        <HelmetMetaData
          image={
            "https://images.unsplash.com/photo-1599420186946-7b6fb4e297f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2787&q=80"
          }
          title={"Kotikino"}
          description={"Kotikino"}
        />
        <Switch>
          {/* ROUTE FOR PLAY VIDEO*/}
          <Route path={`/${settings.routes.playVideo}/:orgId/:asset`}>
            <AssetVideoPlayer
              playerAutoStart={true}
              backButton={true}
              isSerie={false}
              fullBrowser={true}
              routes={settings.routes}
            />
          </Route>

          <Route
            path={`/${settings.routes.shareAsset}/vod/:orgId/:asset`}
            exact
          >
            <Socials routes={settings.routes} />
          </Route>

          <Route>
            <NavBar
              links={
                <>
                  <div className={classes.navBarRow_primary}>
                    <NavBarButton
                      route={settings.routes.videos}
                      name={t("navBar.videos")}
                    />

                    <NavBarButton
                      route={settings.routes.tvod}
                      name={t("navBar.tvod")}
                    />
                    <NavBarCategoryDropDown
                      route={settings.routes.svodCategoriesRoute}
                    />
                  </div>
                  <div className={classes.navRight}>
                    {/* // show only in bigger screen */}
                    {!isResponsiveclose && (
                      <>
                        <NavBarButton
                          route={settings.routes.search}
                          icon="FaSearch"
                        />
                        <LoginBTN routes={settings.routes} />
                      </>
                    )}
                  </div>
                </>
              }
              // small screen
              linksMobileLogin={
                <div className={classes.navRight}>
                  {
                    <LoginBTN
                      routes={settings.routes}
                      hideLogoutSmallScreen={true}
                    />
                  }
                </div>
              }
              // small screen

              linksMobileSearch={
                <div className={classes.navRight}>
                  <NavBarButton
                    route={settings.routes.search}
                    icon="FaSearch"
                  />
                </div>
              }
              // small screen
              linksMobileProfile={
                <div className={classes.navRight}>
                  {<LoginBTN routes={settings.routes} />}
                </div>
              }
            />

            {/* ROUTE FOR FRONT PAGE */}
            <Route path="/" exact>
              <div className="homeContainer">
                {!user.loggedIn && (
                  <>
                    <SignUpBar2 routes={settings.routes} />
                    <GuidesBar routes={settings.routes} />
                  </>
                )}

                <Promo settings={settings.components.frontPagePromo_01} />

                <Assets
                  settings={settings.components.frontPageCategories_01}
                  showDuration={true}
                  showReleaseYear={true}
                  showPublishedDate={false}
                  hideDescription={true}
                />

                <Assets
                  settings={settings.components.frontPageCategories_02}
                  showDuration={true}
                  showReleaseYear={true}
                  showPublishedDate={false}
                  hideDescription={true}
                />
                <BannerSingleAsset
                  settings={settings.components.frontPageBannerSingleAsset_01}
                />
                <GetLatestOrTrendingAssets
                  settings={settings.components.frontPageCategories_01}
                  showDuration={true}
                  showReleaseYear={true}
                  showPublishedDate={false}
                  hideDescription={true}
                  latestData={true}
                  titleName={t("Uutta sisältöä")}
                />
                <GetLatestOrTrendingAssets
                  settings={settings.components.frontPageCategories_01}
                  showDuration={true}
                  showReleaseYear={true}
                  showPublishedDate={false}
                  hideDescription={true}
                  titleName={t("Trendaavat")}
                />

                <TrendingTopShows
                  settings={settings.components.homepageListAllSeries_01}
                  titleName={t("Suositut Sarjat")}
                  hideDescription={true}
                  showCategoryName={true}
                />

                <Assets
                  settings={settings.components.frontPageCategories_03}
                  showDuration={true}
                  showReleaseYear={true}
                  showPublishedDate={false}
                  hideDescription={true}
                />

                {user.loggedIn ? <GuidesBar routes={settings.routes} /> : null}
              </div>
            </Route>

            {/* ROUTE FOR SVOD VIEW*/}
            <Route path={`/${settings.routes.svod}`} exact>
              <div className="homeContainer">
                <Banner settings={settings.components.svodBanner_01} />

                <Promo
                  settings={settings.components.svodPromo_01}
                  hideDescription={true}
                  showDuration={false}
                  showReleaseYear={false}
                  showPublishedDate={false}
                  showCategories={false}
                />

                <Assets
                  settings={settings.components.svodCategories_01}
                  showDuration={true}
                  showReleaseYear={true}
                  showPublishedDate={false}
                  hideDescription={true}
                />
              </div>
            </Route>

            {/* ROUTE FOR TVOD VIEW*/}
            <Route path={`/${settings.routes.tvod}`} exact>
              <div className="homeContainer">
                <Banner settings={settings.components.tvodBanner_01} />

                <Promo
                  settings={settings.components.tvodPromo_01}
                  hideDescription={true}
                  showDuration={false}
                  showReleaseYear={false}
                  showPublishedDate={false}
                  showCategories={false}
                />

                <Assets
                  settings={settings.components.tvodCategories_01}
                  showDuration={true}
                  showReleaseYear={true}
                  showPublishedDate={false}
                  hideDescription={true}
                />
              </div>
            </Route>

            {/* ROUTE FOR SVOD VIDEO */}
            <Route
              path={`/${settings.routes.svodVideoRoute}/:orgId/:asset`}
              exact
            >
              <div style={{ marginBottom: "5%" }}>
                <DetailsVideo2 routes={settings.routes} />

                <Assets
                  settings={settings.components.svodDetailsVideo_Categories_01}
                  showDuration={true}
                  showReleaseYear={true}
                  showPublishedDate={false}
                  hideDescription={true}
                />
              </div>
            </Route>

            {/* ROUTE FOR TVOD VIDEO */}
            <Route
              path={`/${settings.routes.tvodVideoRoute}/:orgId/:asset`}
              exact
            >
              <div style={{ marginBottom: "5%" }}>
                <DetailsVideo2 routes={settings.routes} />

                <Assets
                  settings={settings.components.tvodDetailsVideo_Categories_01}
                  showDuration={true}
                  showReleaseYear={true}
                  showPublishedDate={false}
                  hideDescription={true}
                />
              </div>
            </Route>

            {/* ROUTE FOR PLAY SERIE*/}
            <Route
              path={`/${settings.routes.serieRoute}/:orgId/:asset/:serieId?/:seasonId?`}
            >
              <DetailsVideo2 routes={settings.routes} />

              <DetailsSeasonsAndEpisodes playVideoRoute={"playVideo"} />
            </Route>

            {/* ROUTE FOR SVOD CATEGORIES VIEW*/}
            <Route
              path={`/${settings.routes.svodCategoriesRoute}/:asset`}
              exact
            >
              <ChosenCategoryGrid
                settings={settings.components.svodChosenCategoryGrid_01}
              />
            </Route>

            {/* ROUTE FOR TVOD CATEGORIES VIEW*/}
            <Route
              path={`/${settings.routes.tvodCategoriesRoute}/:asset`}
              exact
            >
              <ChosenCategoryGrid
                settings={settings.components.tvodChosenCategoryGrid_01}
              />
            </Route>

            {/* ROUTE FOR SERIE CATEGORIES VIEW*/}
            <Route
              path={`/${settings.routes.seriesCategoriesRoute}/:orgId/:asset/:serieId?/:seasonId?`}
              exact
            >
              {/* //hideCta hides play button */}
              <DetailsVideo
                hideCta={true}
                hideInfoData={true}
                isSerieCategory={true}
                showPublishedDate={false}
              />
              <DetailsSeasonsAndEpisodes playVideoRoute={"playVideo"} />
            </Route>

            {/* /* ROUTE FOR TV SHOWS/ */}
            <Route path={`/${settings.routes.allSeries}`} exact>
              <HelmetMetaData
                title={chosenItem?.title || chosenItem?.name}
                description={chosenItem?.ingress || chosenItem?.description}
              />
              <EpisodesWithSerieTitles
                settings={settings.components.episodesWithSerieTitles_01}
                showDuration={false}
                showReleaseYear={false}
                showPublishedDate={true}
              />
            </Route>

            {/* ROUTE FOR PURCHASE VIEW */}
            {/*email login for tickets should come here, but not needed with kotikino*/}
            <Route
              path={`/${settings.routes.packages}/:itemType?/:itemId?`}
              exact
            >
              {user.loggedIn ? (
                <LoggedInBox user={user} />
              ) : (
                <LoginForm
                  routes={settings.routes}
                  showBgImage={false}
                  redirectRoute={""}
                  redirect={false}
                />
              )}

              <PurchaseFlow settings={settings.components.purchaseflow} />
            </Route>

            {/* ROUTE FOR PROFILE VIEW*/}
            <Route path={`/${settings.routes.profile}`}>
              <Profile settings={settings.components.profile} />
            </Route>

            {/* ROUTE FOR FAQ VIEW*/}
            <Route path={`/${settings.routes.faq}`}>
              <FAQ />
            </Route>

            {/* ROUTE FOR TOS VIEW*/}
            <Route path={`/${settings.routes.tos}`}>
              <TOS />
            </Route>

            {/* ROUTE FOR PRIVACY  VIEW*/}
            <Route path={`/${settings.routes.privacyTerms}`}>
              <PrivacyTerms />
            </Route>

            {/* ROUTE FOR SEARCH VIEW*/}
            <Route path={`/${settings.routes.search}`}>
              <Search
                settings={settings.components.search_01}
                hideDescription={true}
              />
            </Route>

            {/* ROUTE FOR LOGIN VIEW*/}

            <Route path={`/${settings.routes.login}`}>
              <LoginForm routes={settings.routes} />
            </Route>

            {/* ROUTE FOR SIGNUP VIEW*/}

            <Route path={`/${settings.routes.signUp}`}>
              <SignUpForm
                routes={settings.routes}
                settings={settings.components.signUp}
              />
            </Route>

            {/* ROUTE FOR CONTACT PAGE */}
            <Route path={`/${settings.routes.contactus}`}>
              <ContactUs />
            </Route>

            {/* ROUTE FOR MOREDESKTOP VIEW*/}
            <Route path={`/${settings.routes.guidesDesktop}`}>
              <div className="oneHundredCont">MORE DESKTOP TEXT HERE</div>
            </Route>

            {/* ROUTE FOR MOREMOBILE VIEW*/}
            <Route path={`/${settings.routes.guidesMobile}`}>
              <div className="oneHundredCont">MORE MOBILE TEXT HERE</div>
            </Route>

            {/* ROUTE FOR MOREDESKTOP VIEW*/}
            <Route path={`/${settings.routes.guidesChromecast}`}>
              <div className="oneHundredCont">MORE CHROMECAST TEXT HERE</div>
            </Route>

            <Route path={`/${settings.routes.requestResetPassword}/:token?`}>
              <div className="main-container" style={{ flex: 1 }}>
                <RequestResetPassword />
              </div>
            </Route>

            <BottomBar routes={settings.routes} />
          </Route>
        </Switch>
      </div>
    </div>
  ) : (
    <div
      className="display-flex-center"
      style={{ minHeight: "100vh", background: "#12161d" }}
    >
      <Spinner />
    </div>
  );
}

export default App;
