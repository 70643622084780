import Axios from "axios";
import {
  checkArguments,
  createAccountToken,
  createChangePasswordToken,
} from "./utils";
import { API_Server_Number } from "../configs/config_settings.json";
import { removePunctuationFromResponse } from "../scripts/utils";
import { countries } from "../scripts/countries";

let API_Server = "";

if (API_Server_Number === 1) {
  API_Server = "https://suite.icareus.com";
} else if (API_Server_Number === 2) {
  API_Server = "https://icareus-suite.secure2.footprint.net";
} else if (API_Server_Number === 3) {
  API_Server = "https://suiterc.icareus.com";
}
export const BASE_URL = API_Server;

let API_Server_MY = "https://my.icareus.com";

// Get all category names and id:s
const getCategories = async (
  organizationId,
  key,
  lang,
  user,
  showHidden = false
) => {
  if (checkArguments(organizationId, key, lang)) {
    try {
      const catResponse = await Axios.get(
        `${API_Server}/api/archive?action=getGroups&organizationId=${organizationId}&userId=${
          user?.userId || 0
        }&userToken=${
          user?.userToken || ""
        }&languageId=${lang}&token=${key}&groupTypeName=Folders&showHidden=${showHidden}`
      );
      console.log("getCategories: ", catResponse);
      return catResponse.data.groupItems;
    } catch (err) {
      console.log(err);
    }
  }
};

// Get assets that are listed in groupItemId -string
const getSeries = async (
  organizationId,
  key,
  languageId,
  user,
  assetProperty,
  source
) => {
  if (checkArguments(organizationId, key, languageId)) {
    try {
      const seriesResponse = await Axios.get(
        `${API_Server}/api/archive?action=getGroups`,
        {
          cancelToken: source.token,
          params: {
            organizationId,
            languageId,
            groupTypeName: "Series",
            ...(user?.userId ? { userId: user?.userId } : {}),
            ...(user?.userToken ? { userToken: user?.userToken } : {}),
            ...(assetProperty ? { assetProperty: assetProperty } : {}),
          },
        }
      );

      console.log("getSeries: ", seriesResponse);
      // return removePunctuationFromResponse(seriesResponse.data.groupItems);
      return seriesResponse.data.groupItems;
    } catch (err) {
      console.log(err);
    }
  }
};

// Get subCategories with groupItemId string (single or separated by comma)
const getSubCategories = async (
  organizationId,
  token,
  groupItemIds,
  languageId,
  user,
  source
) => {
  if (checkArguments(organizationId, token, groupItemIds, languageId)) {
    try {
      const catResponse = await Axios.get(
        `${API_Server}/api/archive?action=getGroup`,
        {
          cancelToken: source.token,
          params: {
            organizationId,
            languageId,
            token,
            groupItemIds,
            showHidden: true,
            ...(user?.userId ? { userId: user.userId } : { userId: 0 }),
            ...(user?.userToken
              ? { userToken: user.userToken }
              : { userToken: "" }),
            //...(assetProperty ? { assetProperty: assetProperty} : {assetProperty: ""}),
          },
        }
      );
      console.log("getSubCategories: ", catResponse);
      // return removePunctuationFromResponse(catResponse.data.data);
      return catResponse.data.data;
    } catch (err) {
      console.log(err);
    }
  }
};

// Get subCategories with groupItemId string (single or separated by comma)
const getSeriesGroup = async (
  organizationId,
  groupItemIds,
  languageId,
  user,
  source
) => {
  if (checkArguments(organizationId, groupItemIds, languageId)) {
    try {
      const catResponse = await Axios.get(
        `${API_Server}/api/archive?action=getGroup`,
        {
          cancelToken: source.token,
          params: {
            organizationId,
            languageId,
            groupItemIds,
            groupTypeName: "Series",
            ...(user?.userId ? { userId: user.userId } : { userId: 0 }),
            ...(user?.userToken
              ? { userToken: user.userToken }
              : { userToken: "" }),
            //...(assetProperty ? { assetProperty: assetProperty} : {assetProperty: ""}),
          },
        }
      );
      console.log("getSeriesGroup: ", catResponse);
      return catResponse.data.data;
    } catch (err) {
      console.log(err);
    }
  }
};

// Get all category names and id:s with root category id
const getRootSubCategories = async (
  organizationId,
  key,
  languageId,
  groupItemId,
  user,
  source
) => {
  if (
    checkArguments(organizationId, groupItemId, key, languageId, groupItemId)
  ) {
    try {
      const catResponse = await Axios.get(
        `${API_Server}/api/archive?action=getGroups`,
        {
          cancelToken: source.token,
          params: {
            organizationId,
            languageId,
            groupItemId,
            token: key,
            ...(user?.userId ? { userId: user.userId } : { userId: 0 }),
            ...(user?.userToken
              ? { userToken: user.userToken }
              : { userToken: "" }),
            //...(assetProperty ? { assetProperty: assetProperty} : {assetProperty: ""}),
          },
        }
      );
      console.log("getRootSubCategories: ", catResponse);
      // return removePunctuationFromResponse(catResponse.data.groupItems);
      return catResponse.data.groupItems;
    } catch (err) {
      console.log(err);
    }
  }
};

// Get assets that are listed in groupItemId -string
const getAssets = async (
  organizationId,
  groupItemId,
  token,
  languageId,
  assetProperty,
  user,
  source
) => {
  if (checkArguments(organizationId, groupItemId, token, languageId)) {
    console.log(organizationId, groupItemId, token, languageId);

    console.log(user, "user1");
    try {
      const itemResponse = await Axios.get(
        `${API_Server}/api/publishing/getAssets?version=06&series=false`,
        {
          // cancelToken: source.token,
          params: {
            organizationId,
            languageId,
            groupItemId,
            token,
            ...(user?.userId ? { userId: user?.userId } : {}),
            ...(user?.userToken ? { userToken: user?.userToken } : {}),
            ...(assetProperty ? { assetProperty: assetProperty } : {}),
          },
        }
      );
      console.log("getAssets: Editor ", itemResponse);
      return itemResponse.data.assets;
      // return removePunctuationFromResponse(itemResponse.data.assets);
    } catch (err) {
      console.log(err);
    }
  }
};

// get upcomingEvents based on currentTime
const getUpcomingEvents = async (organizationId, limit, from, to) => {
  try {
    const currentTime = Date.now();
    let url = `${API_Server}/api/events?action=getEvents&version=02&organizationId=${organizationId}&end=${limit}`;

    if (from && !to) {
      url = `${url}&from=${from}`;
    }

    if (from && to) {
      url = `${url}&from=${from}&to=${to}`;
    }
    if (!from && !to) {
      url = `${url}&from=${currentTime}`;
    }

    const res = await Axios.get(url);
    console.log(`res upcoming eventsbeta`, res);

    return res;
  } catch (error) {
    console.log(error);
  }
};

// get upcomingEvents based on currentTime
const getLiveNowEvents = async (organizationId) => {
  try {
    let url = `${API_Server}/api/events?action=getLiveEvents&organizationId=${organizationId}&includeSubOrganizationEvents=true`;

    const res = await Axios.get(url);
    console.log(`getLiveNowEvents: `, res);

    return res;
  } catch (error) {
    console.log(error);
  }
};

// Get all events
const getAllEvents = async (organizationId, categoryId, limit, from, to) => {
  try {
    let url;
    if (categoryId) {
      url = `${API_Server}/api/events?action=getEvents&version=02&organizationId=${organizationId}&categoryIds=${categoryId}&end=${limit}`;
    } else {
      url = `${API_Server}/api/events?action=getEvents&version=02&organizationId=${organizationId}&end=${limit}`;
    }
    if (from && !to) {
      url = `${url}&from=${from}`;
    }

    if (from && to) {
      url = `${url}&from=${from}&to=${to}`;
    }

    const res = await Axios.get(url);

    return res;
  } catch (error) {
    console.log(error);
  }
};
// Get all events
const getAllEventsBeta = async (
  organizationId,
  categoryId,
  limit,
  from,
  to
) => {
  try {
    let url;
    if (categoryId) {
      url = `https://suiterc.icareus.com/api/events?action=getEvents&version=02&organizationId=${organizationId}&categoryIds=${categoryId}&end=${limit}`;
    } else {
      url = `https://suiterc.icareus.com/api/events?action=getEvents&version=02&organizationId=${organizationId}&end=${limit}`;
    }
    if (from && !to) {
      url = `${url}&from=${from}`;
    }

    if (from && to) {
      url = `${url}&from=${from}&to=${to}`;
    }

    const res = await Axios.get(url);

    return res;
  } catch (error) {
    console.log(error);
  }
};

const getSingleEvent = async (organizationId, eventId) => {
  try {
    const res = await Axios.get(
      `${API_Server}/api/events?action=getEvent&version=02&organizationId=${organizationId}&eventId=${eventId}`
    );
    console.log(res);
    return res.data;
  } catch (error) {}
};

const getUpcomingEventsBeta = async (organizationId, limit, from, to) => {
  try {
    const currentTime = Date.now();
    let url = `https://suiterc.icareus.com/api/events?action=getEvents&version=02&organizationId=${organizationId}&end=${limit}`;

    if (from && !to) {
      url = `${url}&from=${from}`;
    }

    if (from && to) {
      url = `${url}&from=${from}&to=${to}`;
    }
    if (!from && !to) {
      url = `${url}&from=${currentTime}`;
    }

    const res = await Axios.get(url);
    console.log(`res upcoming eventsbeta`, res);

    return res;
  } catch (error) {
    console.log(error);
  }
};
const getEventsCategoriesBeta = async (organizationId) => {
  try {
    const res = await Axios.get(
      `https://suiterc.icareus.com/api/events?action=getCategories&version=02&organizationId=${organizationId}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

const getEventsCategories = async (organizationId) => {
  try {
    const res = await Axios.get(
      `${API_Server}/api/events?action=getCategories&version=02&organizationId=${organizationId}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

const getChannels = async (organizationId, secret) => {
  try {
    // const res = await Axios.get(
    //   `https://suite.icareus.com/api/organization?action=getOrganization&version=04&organizationId=69922&includeSubOrganizations=true`
    // );
    let token = secret;
    const res = await Axios.get(
      `${API_Server}/api/organization?action=getOrganization&version=04&organizationId=${organizationId}&includeSubOrganizations=true&token=${token}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

const getChannelStatus = async (organizationId, channelID) => {
  try {
    const res = await Axios.get(
      `${API_Server}/api/channels?action=getChannel&version=04&organizationId=${organizationId}&lcId=${channelID}`
    );
    return res.data.access;
  } catch (error) {
    console.log(error);
  }
};
const getChannelsBeta = async (organizationId, secret) => {
  try {
    // const res = await Axios.get(
    //   `https://suiterc.icareus.com/api/organization?action=getOrganization&version=04&organizationId=3409909&includeSubOrganizations=true`
    // );
    let token = secret ? secret : "tNtSx9xU06";
    const res = await Axios.get(
      `https://suiterc.icareus.com/api/organization?action=getOrganization&version=04&organizationId=${organizationId}&includeSubOrganizations=true&token=${token}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

// Get items that are listed in groupItemId -string
const getSimilar = async (
  organizationId,
  groupItemId,
  token,
  lang,
  assetProperty,
  user
) => {
  if (checkArguments(organizationId, groupItemId, token, lang, assetProperty)) {
    try {
      const itemResponse = await Axios.get(
        `${API_Server}/api/publishing/getAssets?version=06&organizationId=${organizationId}&userId=${
          user?.userId || 0
        }&userToken=${user?.userToken || ""}&assetProperty=${
          assetProperty || ""
        }&orderBy=publishStart&limit=10&languageId=${lang}&groupItemId=${groupItemId}&token=${token}`
      );
      console.log("getSimilar: ", itemResponse);
      return itemResponse.data.assets;
      // return removePunctuationFromResponse(itemResponse.data.assets);
    } catch (err) {
      console.log(err);
    }
  }
};

// Get items that are listed in groupItemId -string
const getAsset = async (
  organizationId,
  assetId,
  token,
  languageId,
  assetProperty,
  user,
  source
) => {
  if (checkArguments(organizationId, assetId, token, languageId)) {
    console.log(user, "user1");

    try {
      const itemResponse = await Axios.get(
        `${API_Server}/api/publishing/getAsset?version=03`,
        {
          cancelToken: source.token,
          params: {
            organizationId,
            languageId,
            token,
            assetId,
            ...(user?.userId ? { userId: user.userId } : { userId: 0 }),
            ...(user?.userToken
              ? { userToken: user.userToken }
              : { userToken: "" }),
            ...(assetProperty
              ? { assetProperty: assetProperty }
              : { assetProperty: "" }),
          },
        }
      );
      console.log("getAsset: ", itemResponse);
      // return removePunctuationFromResponse(itemResponse.data);
      return itemResponse.data;
    } catch (err) {
      console.log(err);
    }
  }
};

// Get serie episodes
const getEpisodes = async (
  organizationId,
  groupItemId,
  token,
  languageId,
  assetProperty,
  user,
  source
) => {
  if (checkArguments(organizationId, groupItemId, token, languageId)) {
    try {
      const episodeResponse = await Axios.get(
        `${API_Server}/api/publishing?action=getAssets&version=06&order=asc`,
        {
          cancelToken: source.token,
          params: {
            organizationId,
            languageId,
            token,
            groupItemId,
            series: false,
            ...(user?.userId ? { userId: user.userId } : { userId: 0 }),
            ...(user?.userToken
              ? { userToken: user.userToken }
              : { userToken: "" }),
            ...(assetProperty
              ? { assetProperty: assetProperty }
              : { assetProperty: "" }),
          },
        }
      );
      console.log("getEpisodes: ", episodeResponse);
      // return removePunctuationFromResponse(episodeResponse.data.assets);
      return episodeResponse.data.assets;
    } catch (err) {
      console.log(err);
    }
  }
};

// Get promoted content
const getPromo = async (
  organizationId,
  groupItemId,
  token,
  languageId,
  assetProperty,
  user,
  source
) => {
  if (checkArguments(organizationId, groupItemId, token, languageId)) {
    try {
      const promoResponse = await Axios.get(
        `${API_Server}/api/publishing?action=getAssets&version=05`,
        {
          cancelToken: source.token,
          params: {
            organizationId,
            languageId,
            token,
            groupItemId,
            series: false,
            orderBy: "publishStart",
            limit: 3,
            ...(user?.userId ? { userId: user.userId } : { userId: 0 }),
            ...(user?.userToken
              ? { userToken: user.userToken }
              : { userToken: "" }),
            ...(assetProperty
              ? { assetProperty: assetProperty }
              : { assetProperty: "" }),
          },
        }
      );
      console.log("getPromo: ", promoResponse);
      return promoResponse.data.assets;
      // return removePunctuationFromResponse(promoResponse.data.assets);
    } catch (err) {
      console.log(err);
    }
  }
};

// Get banner items
const getBanner = async (
  organizationId,
  groupItemId,
  token,
  languageId,
  user,
  source
) => {
  if (checkArguments(organizationId, groupItemId, token, languageId)) {
    try {
      const bannerResponse = await Axios.get(
        `${API_Server}/api/publishing?action=getBanners&version=05`,
        {
          cancelToken: source.token,
          params: {
            organizationId,
            languageId,
            token,
            groupItemId,
            series: false,
            ...(user?.userId ? { userId: user.userId } : { userId: 0 }),
            ...(user?.userToken
              ? { userToken: user.userToken }
              : { userToken: "" }),
            //...(assetProperty ? { assetProperty: assetProperty } : { assetProperty: "" }),
          },
        }
      );
      console.log("getBanner: ", bannerResponse);
      // return removePunctuationFromResponse(bannerResponse.data.assets);
      return bannerResponse.data.assets;
    } catch (err) {
      console.log(err);
    }
  }
};

// Authenticate user
const authenticateUser = async (organizationId, email, password, role) => {
  try {
    let url = `${API_Server_MY}/api/login?action=authenticate`;

    const authResponse = await Axios.get(url, {
      params: {
        organizationId,
        eMail: email,
        password: password,
        ...(role && role
          ? { role: "Super Administrator" }
          : { role: "ismsubscriber" }),
      },
    });
    // console.log("Authentication: ", authResponse);
    return authResponse;
  } catch (err) {
    console.log(err);
  }
};

const registerUser = async (organizationId, inputs, role) => {
  console.log("organizationId", organizationId);
  console.log("inputs", inputs);

  let extraUserSettings = [];

  if (inputs?.ORGANIZATIONNAME?.value) {
    extraUserSettings.push({
      type: "text",
      value: inputs?.ORGANIZATIONNAME?.value,
      name: "organizationName",
    });
  }

  if (inputs?.YTUNNUS?.value) {
    extraUserSettings.push({
      type: "text",
      value: inputs?.YTUNNUS?.value,
      name: "yTunnus",
    });
  }

  try {
    const registerResponse = await Axios({
      method: "post",
      url: `${API_Server_MY}/api/register`,
      params: {
        action: "addSubscriber",
        organizationId,
        emailAddress: inputs?.EMAIL?.value,
        userPassword: inputs?.PASSWORD?.value,
        ...(inputs?.PHONE?.value ? { phoneNumber: inputs?.PHONE?.value } : "-"),
        ...(inputs?.CITY?.value ? { cityName: inputs?.CITY?.value } : "-"),
        ...(inputs?.DATEOFBIRTH?.value
          ? { dateOfBirth: inputs?.DATEOFBIRTH?.value }
          : "-"),
        ...(inputs?.COUNTRY?.value
          ? {
              countryId: countries.find(
                (country) => country.name === inputs?.COUNTRY?.value
              ).id,
            }
          : "-"),
        ...(inputs?.FIRSTNAME?.value
          ? { firstName: inputs?.FIRSTNAME?.value }
          : "-"),
        ...(inputs?.LASTNAME?.value
          ? { lastName: inputs?.LASTNAME?.value }
          : "-"),
        ...(inputs?.ADDRESS?.value
          ? { address1: inputs?.ADDRESS?.value }
          : "-"),
        ...(inputs?.POSTALCODE?.value
          ? { postalCode: inputs?.POSTALCODE?.value }
          : "-"),
        ...(extraUserSettings.length > 0
          ? { extraUserSettings: JSON.stringify(extraUserSettings) }
          : {}),
        ...(role ? { role: role } : { role: "ismsubscriber" }),
      },
    });

    console.log("Register: ", registerResponse);
    return registerResponse;
  } catch (err) {
    console.log(err);
  }
};
export const resetPasswordChange = async (
  newPassword,
  confirmPassword,
  token
) => {
  let url = `${API_Server}/api/user?action=setNewPassword`;
  const res = await Axios.get(url, {
    params: {
      newPassword,
      confirmPassword,
      token,
    },
  });

  console.log("reset password response: ", res);
  return res;
};

export const validatePasswordResetToken = async (token) => {
  let url = `${API_Server}/api/user?action=validatePasswordToken`;
  const res = await Axios.get(url, {
    params: {
      token,
    },
  });

  console.log("validatePasswordResetToken response: ", res);
  return res;
};
export const requestResetPassword = async (
  emailAddress,
  organizationId,
  role,
  token
) => {
  let url = `${API_Server}/api/user?action=resetPassword`;
  const requestResetPasswordResponse = await Axios.get(url, {
    params: {
      organizationId,
      emailAddress,
      role,
      token,
    },
  });

  console.log("requestResetPassword response: ", requestResetPasswordResponse);
  return requestResetPasswordResponse;
};
const changePassword = async (
  organizationId,
  key,
  userId,
  newPassword,
  confirmPassword,
  oldPassword,
  userToken
) => {
  const token = createChangePasswordToken(
    organizationId,
    key,
    userId,
    newPassword,
    confirmPassword
  );

  try {
    // TODO: API CHANGES TO PASSWORD CHANGE REQUEST
    const changePasswordResponse = await Axios.get(
      `${API_Server}/api/user?action=changePassword&organizationId=${organizationId}&userId=${userId}&newPassword=${newPassword}&confirmPassword=${confirmPassword}&token=${token}&oldPassword=${oldPassword}&userToken=${userToken}`
    );

    console.log("ChangePassword: ", changePasswordResponse);
    return changePasswordResponse;
  } catch (err) {
    console.log(err);
    return err;
  }
};

// Update user
const updateUser = async (
  userId,
  userToken,
  organizationId,
  key,
  firstName,
  lastName,
  phoneNumber,
  countryId,
  regionId,
  cityName,
  postalCode,
  emailAddress,
  organizationName,
  yTunnus,
  dateOfBirth,
  address
) => {
  const token = createAccountToken(
    userId,
    organizationId,
    key,
    firstName,
    lastName,
    phoneNumber,
    countryId,
    regionId,
    cityName,
    postalCode
  );

  let extraUserSettings = [];

  if (organizationName) {
    extraUserSettings.push({
      type: "text",
      value: organizationName,
      name: "organizationName",
    });
  }

  if (yTunnus) {
    extraUserSettings.push({
      type: "text",
      value: yTunnus,
      name: "yTunnus",
    });
  }

  try {
    const updateResponse = await Axios.get(`${API_Server}/api/user`, {
      params: {
        action: "updateUser",
        organizationId,
        userToken,
        firstName,
        lastName,
        emailAddress,
        countryId,
        regionId,
        postalCode,
        cityName,
        phoneNumber,
        token,
        extraUserSettings: JSON.stringify(extraUserSettings),
        ...(dateOfBirth ? { dateOfBirth: dateOfBirth } : {}),
        ...(address ? { address1: address } : {}),
      },
    });
    console.log("updateUser: ", updateResponse);
    return updateResponse;
  } catch (err) {
    console.log(err);
  }
};
// Get user data
const getUser = async (userToken, organizationId) => {
  try {
    const getUserResponse = await Axios.get(
      `${API_Server}/api/user?action=getUserProfile&organizationId=${organizationId}&userToken=${userToken}`
    );
    console.log("getUser: ", getUserResponse);
    return getUserResponse;
  } catch (err) {
    console.log(err);
  }
};

// Search assets
const searchAssets = async (keyword, user, organizationId, language) => {
  try {
    const getSearchResponse = await Axios.get(
      `${API_Server}/api/search/assets?action=searchAssets`,
      {
        params: {
          organizationId,
          keyword: keyword,
          ...(user?.userToken ? { userToken: user?.userToken } : {}),
          ...(language ? { language: language } : {}),
          ...(user?.userId ? { userId: user?.userId } : {}),
        },
      }
    );

    console.log("searchAsset: ", getSearchResponse);
    return getSearchResponse;
  } catch (err) {
    console.log(err);
  }
};

const getPlayerConfig = async (organizationId, channelID) => {
  try {
    const res = await Axios.get(
      `${API_Server}/api/applications?action=getAppConfig&appId=133485121`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// Get organizations packages based on given object (if no object given it will fetch all packages)
const getPackages = async (organizationId, lang, objectId, userToken) => {
  if (checkArguments(organizationId, lang)) {
    try {
      const params = {
        action: "getPackages",
        organizationId,
        languageId: lang,
      };
      if (objectId > 0) {
        params.objectId = objectId;
      }
      if (userToken) {
        params.userToken = userToken;
      }
      const packagesResponse = await Axios.get(`${API_Server}/api/packages`, {
        params,
      });
      console.log("Org packages: ", packagesResponse);
      return packagesResponse.data;
    } catch (err) {
      console.log(err);
    }
  }
};

// Get all shopping categories for organization
const getShoppingCategories = async (organizationId, lang) => {
  if (checkArguments(organizationId, lang)) {
    try {
      const params = {
        action: "getShoppingCategories",
        organizationId,
      };
      const shoppingCategoriesResponse = await Axios.get(
        `${API_Server}/api/packages`,
        { params }
      );
      console.log("Shopping Cats: ", shoppingCategoriesResponse);
      return shoppingCategoriesResponse.data;
    } catch (err) {
      console.log(err);
    }
  }
};

// Get organizations payment methods
const getPaymentMethods = async (organizationId) => {
  try {
    const params = {
      action: "getPaymentMethods",
      organizationId,
    };
    const paymentMethodsResponse = await Axios.get(
      `${API_Server}/api/payment/methods`,
      { params }
    );
    console.log("Payment methods: ", paymentMethodsResponse);
    return paymentMethodsResponse.data;
  } catch (err) {
    console.log(err);
  }
};

//get payment url
const getPaymentUrl = async (
  userToken,
  organizationId,
  packageId,
  paymentMethodId,
  userEmail,
  handlerUrl
) => {
  try {
    const params = {
      action: "initPurchase",
      userToken,
      organizationId,
      packageId,
      paymentMethodId,
      userEmail,
      handlerUrl,
    };
    const paymentUrlResponse = await Axios.get(`${API_Server}/api/purchase`, {
      params,
    });
    console.log("Url", paymentUrlResponse);
    return paymentUrlResponse.data;
  } catch (err) {
    console.log(err);
  }
};

// return from bambora payment
const purchasePackage = async (userToken, oldParams) => {
  try {
    let params = oldParams;
    params.userToken = userToken;
    params.action = "return";

    const purchasePackageResponse = await Axios.get(
      `${API_Server}/api/purchase/return`,
      { params }
    );

    console.log("PP: ", purchasePackageResponse);
    return purchasePackageResponse.data;
  } catch (err) {
    console.log(err);
  }
};

// Try to make Voucher purchase
const voucherPurchase = async (
  userToken,
  languageId,
  organizationId,
  productId,
  voucherCode,
  quantity
) => {
  try {
    const params = {
      userToken,
      action: "activateVoucher",
      voucherCode,
      languageId,
      productId,
      usedDate: new Date().getTime(),
      organizationId,
      quantity,
    };
    console.log("PAR: ", params);
    const voucherPurchaseResponse = await Axios.get(
      `${API_Server}/api/purchase`,
      { params }
    );
    console.log("VPR: ", voucherPurchaseResponse);
    return voucherPurchaseResponse;
  } catch (err) {
    console.log(err);
  }
};

// get TRENDING TOP SHOWS
const getTrendingShow = async (organizationId, token, days, amount, source) => {
  if (checkArguments(organizationId, token)) {
    try {
      const res = await Axios.get(
        `${API_Server}/api/publishing?action=getTopShows`,
        {
          params: {
            cancelToken: source.token,
            groupTypeName: "series",
            token,
            organizationId,
            days: days ? days : 30,
            amount: amount ? amount : 10,
          },
        }
      );
      const res1 = res.data.groupItems.map((el) => el.groupItem);

      console.log(res, "trending topshows");
      console.log(res1, "trending topshows res1");

      return res1;
    } catch (error) {
      console.log(error);
    }
  }
};

// get Latest Assets
const getLatestAssets = async (
  organizationId,
  token,
  languageId,
  limit,
  assetProperty,
  user,
  source
) => {
  if (checkArguments(organizationId, token, languageId)) {
    try {
      const itemResponse = await Axios.get(
        `${API_Server}/api/publishing?action=getLatestAssets`,
        {
          params: {
            cancelToken: source.token,
            organizationId,
            languageId,
            limit: limit ? limit : 10,
            onlyVideos: true,
            token,
            ...(user?.userId ? { userId: user?.userId } : {}),
            ...(user?.userToken ? { userToken: user?.userToken } : {}),
            ...(assetProperty ? { assetProperty: assetProperty } : {}),
          },
        }
      );
      console.log("get latest Asset: ", itemResponse);
      return itemResponse.data;
    } catch (err) {
      console.log(err);
    }
  }
};
const getTrendingAssets = async (
  organizationId,
  token,
  languageId,
  limit,
  days,
  assetProperty,
  user,
  source
) => {
  if (checkArguments(organizationId, token, languageId)) {
    try {
      const res = await Axios.get(
        `${API_Server}/api/publishing?action=getMostPopular`,
        {
          params: {
            cancelToken: source.token,
            organizationId: organizationId * 1,
            amount: limit ? limit : 30,
            days: days ? days : 365,
            onlyVideos: true,
            token,
            languageId,
            ...(user?.userId ? { userId: user?.userId } : {}),
            ...(user?.userToken ? { userToken: user?.userToken } : {}),
            ...(assetProperty ? { assetProperty: assetProperty } : {}),
          },
        }
      );
      console.log("get trending Asset: ", res);
      const res1 = res.data.assets.map((el) => el.asset);

      return res1;
    } catch (err) {
      console.log(err);
    }
  }
};

export {
  getPackages,
  getPaymentMethods,
  getPaymentUrl,
  purchasePackage,
  voucherPurchase,
  getCategories,
  getRootSubCategories,
  getSubCategories,
  getSeries,
  getLiveNowEvents,
  getEventsCategories,
  getSingleEvent,
  getAllEvents,
  getEventsCategoriesBeta,
  getAllEventsBeta,
  getChannelsBeta,
  getUpcomingEventsBeta,
  getChannels,
  getUpcomingEvents,
  getAssets,
  getSimilar,
  getAsset,
  getEpisodes,
  getPromo,
  getBanner,
  getUser,
  authenticateUser,
  changePassword,
  registerUser,
  searchAssets,
  updateUser,
  getSeriesGroup,
  getChannelStatus,
  getPlayerConfig,
  getTrendingShow,
  getTrendingAssets,
  getLatestAssets,
};
