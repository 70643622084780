import { useMyContext } from '../../../contexts/StateHolder';
import { useEffect, useState } from 'react';
import { getBanner } from '../../../scripts/dataHandlers';
import { createToken, shuffle } from '../../../scripts/utils';
import BannerSingleAssetChildItem1 from './ChildItems/BannerSingleAssetChildItem1';
import axios from 'axios';

import * as classes from './BannerSingleAsset.module.css';

const BannerSingleAsset = (props) => {
  // Destructure props.settings
  const { groupItemId, routes, bannerComponent } =
    props.settings;

  const [bannerItem, setBannerItem] = useState("");

  // Bring stateholders from context
  const {
    key,
    language,
    organizationId,
    user,
  } = useMyContext();

  // List of usable item title and image components
  const components = {
    BannerSingleAssetChildItem1,
  };

  useEffect(() => {

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function getData() {
      try {
        // Create token for promo items
        const token = createToken(organizationId, groupItemId, key);

        // Call getItems datahandler to get list of items from categories
        const response = await getBanner(
          organizationId,
          groupItemId,
          token,
          language,
          user,
          source
        );

        // Shuffle response array, so viewed asset is always random
        const shuffledArray = shuffle(response);

        // Set first asset from array as viewed item
        setBannerItem(shuffledArray[0]);
      } catch (err) {
        console.log(err);
      }
    }

    if (organizationId && groupItemId && key && language) {
      getData();
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, user]);

  const RenderBannerComponent = components[bannerComponent];

  if (bannerItem) {
    return (
      <div className={classes.singleAssetContainer}>
        <RenderBannerComponent
          item={bannerItem}
          id={bannerItem.id}
          key={bannerItem.id}
          routes={routes}
          organizationId={organizationId}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default BannerSingleAsset;
