export const getUniqueSerieAndAssets = (assets) => {
  // filtering assets which includes isSeries or there is seriesId
  let allSerieAsset = assets?.filter((el) => el?.seriesId || el.isSerie);

  // remaining assets from assets after filtering all series stuff
  let remainingData = assets?.filter((el) => !allSerieAsset?.includes(el));

  // getting unique seriesid

  const uniqueArray = allSerieAsset.filter((item, index, self) => {
    return (
      !index ||
      !self
        .slice(0, index)
        .some((prevItem) => prevItem.seriesId === item.seriesId)
    );
  });

  return [...remainingData, ...uniqueArray];
};
