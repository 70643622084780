import { useQuery } from "react-query";

import * as settings from "../../../configs/config_settings.json";
import { createToken } from "../../../scripts/utils";
import { useMyContext } from "../../../contexts/StateHolder";
import SingleCateoryAssets from "./SingleCateoryAssets";
import { reactQuerygetRootSubCategories } from "../../../scripts/dataHandlerReactquery";

const SHOW_HIDDEN_CATEGORY = true;

const AssetsMode3 = (props) => {
    // mode 3: get subcategories and their assets, ignoring hidden categories from asset folders->it fetches hidden cat as well so pass a flag to data handler

    const {
        id,
        groupItemId,

        assetProperty,
    } = props.settings;
    const { organizationId, key } = settings.organization;
    const { language } = settings.language;

    const { user } = useMyContext();

    const fetchSubCategories = () => {
        const token = createToken(organizationId, groupItemId, key);

        return reactQuerygetRootSubCategories(organizationId, token, groupItemId, language, user, assetProperty, SHOW_HIDDEN_CATEGORY);
    };

    const { data: categories, isLoading } = useQuery(`${id}AssetMode3${groupItemId}`, fetchSubCategories, {
        // by default the cache time is 5 minutes
        cacheTime: 300000,
        staleTime: Infinity,
    });

    console.log("assets", categories?.data?.data, isLoading);

    if (!isLoading) {
        return categories?.data?.data[0].groupItems.map((el, i) => <SingleCateoryAssets category={el} {...props} key={el?.id || i.toString()} />);
    } else {
        return null;
    }
};

export default AssetsMode3;
