import { useMyContext } from "../../../../contexts/StateHolder";
import DetailsFolderNames from "../../DetailsFolderNames";
import DetailsContentRatings from "../../DetailsContentRatings";
import DetailsInfoData from "../../DetailsInfoData";
import DetailsItemDescription from "../../DetailsItemDescription";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  createAssetIdToken,
  createToken,
  removePunctuation,
} from "../../../../scripts/utils";
import { getImageByKey } from "../../../../scripts/getImageByKey";
import { getAsset, getSubCategories } from "../../../../scripts/dataHandlers";
import DetailsShare from "../../DetailsShare";
import * as classes from "./DetailsVideo2.module.css";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import moment from "moment";
import HelmetMetaData from "../../../ShareSocialMedia/HelmetMetaData";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../WindowDimension";
import ShowMoreText from "react-show-more-text";
import ReactModal from "react-modal";
import LoginForm from "../../../Login/LoginForm";

import * as settings from "../../../../configs/config_settings.json";
import { customStyles } from "../../../../lib/getLoginModalCustomStyles";
import { useCallback } from "react";
const DetailsVideo = (props) => {
  // Bring stateholders from context
  const {
    chosenItem,
    setChosenItem,
    language,
    key,
    organizationId,
    user,
    loading,
    setLoading,
  } = useMyContext();

  const [backgroundImage, setBackgroundImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  // show login modal when user
  const [showUserModal, setShowUserModal] = useState(false);

  const { asset } = useParams();

  const history = useHistory();

  const { t } = useTranslation();

  const [cookies, setCookie] = useCookies("");
  const location = useLocation();

  // manage window dimension
  const windowDimension = useWindowDimensions();

  // show/hide text description
  const executeOnClick = (isExpanded) => {
    console.log(isExpanded);
  };

  const fetchAsset = useCallback(
    async (source, updatedUser) => {
      setChosenItem(null);
      setLoading(true);
      try {
        // Call createToken function to create new token from given data
        let token = createAssetIdToken(organizationId, asset, language, key);

        const assetResponse = await getAsset(
          organizationId,
          asset,
          token,
          language,
          undefined, // TODO: MOVE TO COMPONENT CONFIG
          updatedUser ? updatedUser : user,
          source
        );
        console.log(`assetResponse`, assetResponse);

        setChosenItem(assetResponse);
        setLoading(false);
        return assetResponse;
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    },
    [asset, key, language, organizationId, props.isSerieCategory]
  );

  const fetchSerieCategoryAsset = useCallback(
    async (source) => {
      setChosenItem(null);
      setLoading(true);

      try {
        let token3 = createToken(organizationId, asset, key);

        const response3 = await getSubCategories(
          organizationId,
          token3,
          asset,
          language,
          user,
          source
        );
        console.log(`response3`, response3);

        setChosenItem(response3[0]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [asset, key, language, organizationId, props.isSerieCategory]
  );

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (language) {
      // Props isSerieCategory can turn this component into showing main serie data. By default it shows episode data.
      props.isSerieCategory
        ? fetchSerieCategoryAsset(source)
        : fetchAsset(source);
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, key, language, organizationId, props.isSerieCategory]);

  useEffect(() => {
    if (chosenItem?.bannerImageSmall || chosenItem?.serie?.bannerImageSmall) {
      setBackgroundImage(
        chosenItem.isSerie
          ? chosenItem?.serie?.bannerImageSmall || chosenItem.bannerImageSmall
          : chosenItem.bannerImageSmall
      );
    }

    if (chosenItem?.coverImageSmall || chosenItem?.serie?.coverImageSmall) {
      setCoverImage(
        chosenItem.isSerie
          ? chosenItem?.serie?.coverImageSmall || chosenItem.coverImageSmall
          : chosenItem.coverImageSmall
      );
    }
  }, [chosenItem]);

  // "Watch button's function"
  const clickAction = () => {
    /* Conditions to check if user has access to product
    or is there product available for assets at all. */

    // first of all we should ask for login here

    if (!user?.loggedIn) {
      // we need show login modal
      setShowUserModal(true);
      return;
    }
    checkUserAccessForAsset(chosenItem);
  };

  const checkUserAccessForAsset = (item) => {
    if (item.accessInfo?.access === 1) {
      // If user has access to product, push to playVideo route
      history.push(`/${props.routes.playVideo}/${organizationId}/${asset}`);
    } else if (item.accessInfo?.products) {
      if (item.accessInfo?.products?.length === 0) {
        // If there's no products (doesnt require payment) for asset, push to playVideo route
        history.push(`/${props.routes.playVideo}/${organizationId}/${asset}`);
      } else {
        // If there's product available, but user doesnt have access, push to packages route
        setCookie(
          "packageReturn",
          { returnPath: location.pathname },
          { path: "/", secure: true, sameSite: "none" }
        );

        history.push(`/${props.routes.packages}/asset/${asset}`);
      }
    }
  };

  const renderDetails = () => {
    return (
      <div className={classes.detailsContainer}>
        <div className={classes.backgroundImageWrapper}>
          {chosenItem?.serie?.bannerImageSmall ||
          chosenItem.bannerImageSmall ? (
            <div
              className={classes.backgroundImage}
              style={{
                backgroundImage: `linear-gradient(190deg, rgba(43,48,55,0.8) ${
                  windowDimension.width >= 1000 ? 93 : 98
                }%,rgba(243,153,57,0.9) ${
                  windowDimension.width >= 1000 ? 7 : 2
                }%),
                    url(${
                      backgroundImage
                        ? backgroundImage
                        : getImageByKey("channelBannerDemo")
                    })`,

                // backgroundSize: "100% 100%",
                // backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            />
          ) : null}
        </div>

        <HelmetMetaData
          image={
            props.chosenItem?.bannerImageSmall ||
            props.chosenItem?.coverImageSmall ||
            props.chosenImage?.thumbnailSmall
          }
          title={chosenItem?.title || chosenItem?.name}
          description={chosenItem?.ingress || chosenItem?.description}
        />

        <div className={classes.detailsInnerContainer}>
          <div className={classes.detailsInnerContainerLeft}>
            {chosenItem?.serie?.coverImageSmall ||
            chosenItem.coverImageSmall ? (
              <img
                className={classes.coverImage}
                src={coverImage ? coverImage : getImageByKey("comingSoon")}
                alt="coverImage"
              />
            ) : null}
          </div>

          <div className={classes.detailsInnerContainerRight}>
            <div className={classes.detailsDataContainer}>
              <div className={classes.detailsTitleContainer}>
                <div className={`${classes.detailsTitle} font-600  `}>
                  {`${removePunctuation(
                    chosenItem?.name ? chosenItem.name : chosenItem.title
                  )}`}
                </div>

                {/* Show contentRatings, unless props has hideContentRatings={true} */}
                {!props.hideContentRatings &&
                  chosenItem.contentRatings?.length > 0 && (
                    <div className={classes.detailsContentRatings}>
                      <DetailsContentRatings size={"35px"} />{" "}
                    </div>
                  )}
              </div>

              {/* If chosenItem has folders, show them */}
              {chosenItem.folders ? (
                <DetailsFolderNames routes={props.routes} />
              ) : null}

              {/* If props has showDate={true}, show date of asset */}
              {props.showDate ? (
                <div
                  className="asset-date font-000"
                  style={{ paddingTop: "0px", color: "rgb(255 255 255 / 80%)" }}
                >
                  {moment(chosenItem.date).locale("fr").format("l")}
                </div>
              ) : null}

              <div className={classes.detailsDescriptionContainer}>
                <ShowMoreText
                  /* Default options */
                  lines={10}
                  more={t("guidesBar.Read more")}
                  less="Show less"
                  className={classes.showMoreText}
                  anchorClass={classes.showMoreLessClickable}
                  onClick={executeOnClick}
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  <DetailsItemDescription
                    // extraClassName='font-200'
                    showAll={true}
                    desc={
                      chosenItem.ingress ||
                      chosenItem?.description ||
                      chosenItem?.serie?.description
                    }
                    size="1000000"
                    mobileSize="1000000"
                    sanitizeHTML={true}
                    color={"rgb(255 255 255 / 80%)"}
                  />
                </ShowMoreText>
              </div>

              {/* If this component is configured with isSerieCategory, show play button in details */}
              {!props.isSerieCategory ? (
                <div className={classes.actionButtonRow}>
                  <button
                    className={classes.first}
                    onClick={() => clickAction()}
                  >
                    {t("detailsVideo.action")}
                  </button>
                </div>
              ) : null}

              {/* If props are configured with hideInfoData, dont show them. By default, it will show them*/}
              {!props.hideInfoData ? (
                <div className="detailsInfoContainer">
                  <DetailsInfoData
                    item={chosenItem}
                    color={"rgb(255 255 255 / 80%)"}
                  />
                </div>
              ) : null}

              {/* If props are configured with hideShare, dont show social media buttons. By default, it will show them*/}
              {!props.hideShare ? (
                <DetailsShare
                  item={chosenItem}
                  isSerieCategory={props.isSerieCategory}
                  showEmbed={false}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const checkUserAccessAfterLogin = async (loginData) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    let updatedUser = { ...loginData };
    updatedUser.userToken = updatedUser.user_token;

    const updatedItemResponse = props.isSerieCategory
      ? await fetchSerieCategoryAsset(source)
      : await fetchAsset(source, updatedUser);
    checkUserAccessForAsset(updatedItemResponse);
  };

  return chosenItem ? (
    <>
      <ReactModal
        isOpen={showUserModal}
        style={customStyles}
        onRequestClose={() => setShowUserModal(false)}
      >
        <LoginForm
          routes={settings.routes}
          formPrimaryStyle={{ background: "#1e2228" }}
          customCallback={checkUserAccessAfterLogin}
        />
      </ReactModal>
      {renderDetails()}
    </>
  ) : (
    !chosenItem && loading && (
      <div className="display-flex-center">
        <TailSpin color="#dfdfdf" height={50} width={50} />
      </div>
    )
  );
};

export default DetailsVideo;
