import { useQuery } from "react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import CategoryTitle from "../CategoryTitles/CategoryTitle";
import CategoryTitle2 from "../CategoryTitles/CategoryTitle2/CategoryTitle2";
import useWindowDimensions from "../../WindowDimension";
import * as settings from "../../../configs/config_settings.json";
import { useMyContext } from "../../../contexts/StateHolder";
import {
  createToken,
  manageCarouselResponsiveness,
} from "../../../scripts/utils";

import RenderItem from "../RenderItems/RenderItem";
import { reactQueryfetchCategoryAssets } from "../../../scripts/dataHandlerReactquery";
import AssetsCustomCarousel, {
  AssetsCustomCarouselSlickSettings,
} from "../../../Shared/CustomCarousel/AssetsCustomCarousel";
import { getUniqueSerieAndAssets } from "../../../lib/getUniqueSerieAndAssets";

const components = {
  CategoryTitle,
  CategoryTitle2,
};

const SingleCateoryAssets = ({ category, ...props }) => {
  const windowDimension = useWindowDimensions();

  const {
    id,
    assetProperty,

    routes,
    categoryTitleComponent,
    itemImageComponent,
    itemTitleComponent,
    maintainImageAspectRatio,
    imageType,
    cutText,
  } = props.settings;

  const { organizationId, key } = settings.organization;
  const { language } = settings.language;

  const { setChosenItem, user } = useMyContext();

  const fetchAssets = () => {
    const token = createToken(organizationId, category.id, key);

    return reactQueryfetchCategoryAssets(
      organizationId,
      category.id,
      token,
      language,
      assetProperty,
      user
    );
  };

  // need to pass unique key to react use query

  const { data: assets, isLoading } = useQuery(
    `${id}getAssets${category.id}`,
    fetchAssets,

    {
      // by default the cache time is 5 minutes
      cacheTime: 300000,
      staleTime: Infinity,
      select: (res) => {
        return getUniqueSerieAndAssets(res?.data?.assets);
      },
    }
  );

  console.log("assets", assets);

  const clickHandler = (item, i) => {
    setChosenItem(item);
  };

  const skeletonItem = [1, 2, 3, 4, 5, 6, 7, 8];
  const TitleComponent = components[categoryTitleComponent];
  let categoryPushRoute = `/${routes.categories}/${category.id}`;
  let className1 = "";

  const RenderAsset = () => {
    if (assets) {
      className1 = manageCarouselResponsiveness(
        AssetsCustomCarouselSlickSettings,
        windowDimension,
        assets,
        className1
      );
    }

    return (
      <>
        <div
          className={`${className1} categoriesContainer`}
          key={`${id} ${category?.id}`}
        >
          <TitleComponent
            id={category.id}
            title={category.title}
            routes={routes}
            item={category}
            showSerieViewAll={true}
            showViewAll={assets?.length > 0 ? true : false}
            pushRoute={categoryPushRoute}
            extraClassname={props.extraClassname}
          />
          <AssetsCustomCarousel>
            {assets?.map((item, i) => {
              let pushRoute = "";

              if (item.isSerie === true || item.series?.length > 0) {
                // FROM NOW ALL SERIES WILL BE TAKEN TO THEIR MAIN PAGE- NOT SHOW ANY EPISODE PAGE ANY MORE
                pushRoute = `/series/categories/${organizationId}/${item?.seriesId}`;
              } else {
                pushRoute = `/${routes.videoRoute}/${organizationId}/${item.id}`;
              }

              return (
                <div key={`${id} ${i}`} onClick={() => clickHandler(item, i)}>
                  <RenderItem
                    key={`${id} ${i}`}
                    item={item}
                    routes={routes}
                    itemImageComponent={itemImageComponent}
                    itemTitleComponent={itemTitleComponent}
                    pushRoute={pushRoute}
                    imageType={imageType}
                    showCategoryName={false}
                    showPlayIcon={true}
                    // whether to maintain aspect ratio 16/9
                    maintainImageAspectRatio={maintainImageAspectRatio}
                    showDuration={props.showDuration}
                    showReleaseYear={props.showReleaseYear}
                    showPublishedDate={props.showPublishedDate}
                    cutText={cutText}
                    hideDescription={props.hideDescription}
                    showTitle={true}
                    folderPage={true}
                  />
                </div>
              );
            })}
          </AssetsCustomCarousel>
        </div>
      </>
    );
  };

  const RenderLoading = () => {
    return (
      <div
        className={`${className1} categoriesContainer`}
        key={`${id} ${category?.id}`}
      >
        <TitleComponent
          id={category.id}
          title={category.title}
          routes={routes}
          item={category}
          showSerieViewAll={false}
          extraClassname={props.extraClassname}
        />
        <AssetsCustomCarousel {...AssetsCustomCarouselSlickSettings}>
          {skeletonItem.map((el2) => (
            <SkeletonTheme key={el2}>
              <p>
                <Skeleton
                  key={el2}
                  width={"95%"}
                  height={"315px"}
                  style={{
                    borderRadius: "16px",
                  }}
                />
              </p>
            </SkeletonTheme>
          ))}
        </AssetsCustomCarousel>
      </div>
    );
  };
  return !isLoading ? <RenderAsset /> : <RenderLoading />;
};

export default SingleCateoryAssets;
