// Depending upon mode we will send it to different component
import AssetsMode1 from "../components/ViewAssets/Assets/AssetsMode1";
import AssetsMode2 from "../components/ViewAssets/Assets/AssetsMode2";
import AssetsMode3 from "../components/ViewAssets/Assets/AssetsMode3";
import AssetsMode4 from "../components/ViewAssets/Assets/AssetsMode4";

const components = [AssetsMode1, AssetsMode2, AssetsMode3, AssetsMode4];

const Assets = (props) => {
    const { mode } = props.settings;

    const AssetsComponent = components[mode.split("_")[1] * 1 - 1];

    return <AssetsComponent {...props} />;
};

export default Assets;
