import React from "react";
import Carousel from "react-multi-carousel";

export const AssetsCustomCarouselSlickSettings = {
  dots: false,
  infinite: false,
  axis: "horizontal",
  lazyLoad: false,
  autoPlaySpeed: 500,
  partialVisible: true,
  autoPlay: false,
  responsive: {
    "4kScreen": {
      breakpoint: {
        max: 8800,
        min: 2999,
      },
      items: 11,

      partialVisibilityGutter: 20,
      slidesToSlide: 4,
    },
    "2kScreen": {
      breakpoint: {
        max: 3000,
        min: 1921,
      },
      items: 8,

      partialVisibilityGutter: 20,
      slidesToSlide: 4,
    },
    "s1.1": {
      breakpoint: {
        max: 1920,
        min: 1550,
      },
      items: 6,
      itemWidth: 300,
      partialVisibilityGutter: 20,
      slidesToSlide: 2,
    },
    s2: {
      breakpoint: {
        max: 1549,
        min: 1200,
      },
      items: 5,
      itemWidth: 300,

      partialVisibilityGutter: 20,
      slidesToSlide: 2,
    },
    s3: {
      breakpoint: {
        max: 1199,
        min: 1020,
      },
      items: 5,
      itemWidth: 300,
      partialVisibilityGutter: 20,
      slidesToSlide: 2,
    },
    s4: {
      breakpoint: {
        max: 1019,
        min: 950,
      },
      items: 4,
      itemWidth: 300,
      partialVisibilityGutter: 40,
      slidesToSlide: 1,
    },
    s5: {
      breakpoint: {
        max: 949,
        min: 549,
      },
      items: 3,
      itemWidth: 300,
      partialVisibilityGutter: 20,
      slidesToSlide: 1,
    },

    s7: {
      breakpoint: {
        max: 550,
        min: 450,
      },
      items: 2,
      partialVisibilityGutter: 50,
      slidesToSlide: 1,
    },
    s8: {
      breakpoint: {
        max: 450,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 100,
      slidesToSlide: 1,
    },
  },
};

const AssetsCustomCarousel = ({ children }) => {
  return <Carousel {...AssetsCustomCarouselSlickSettings}>{children}</Carousel>;
};

export default AssetsCustomCarousel;
